import {
  ArrowLeftOutlined,
  LeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React from "react";
import { useState } from "react";
import AddNewItem from "./AddNewItem";
import CurrentStock from "./CurrentStock";
import { Breadcrumb, Button } from "antd";
import Discounts from "./Discounts";
import Coupons from "./Coupons";
import Orders from "./Orders";
import Dashboards from "./Dashboards";
import { useNavigate } from "react-router-dom";

function Admin() {
  const [menu, setMenu] = useState("");
  const navigate = useNavigate();
  const menuItems = [
    { name: "addNewItem", title: "Add New Item" },
    { name: "orders", title: "Orders" },
    { name: "currentStock", title: "Current Stock" },
    // { name: "discounts&offers", title: "Discounts & Offers" },
    // { name: "coupns", title: "Coupns" },
    { name: "dashboards", title: "Dashboards" },
  ];
  const backToMainMenu = () => setMenu("");
  const ContentToShhow = () => {
    switch (menu) {
      case "addNewItem":
        return <AddNewItem back={backToMainMenu} />;
      case "orders":
        return <Orders back={backToMainMenu} />;
      case "currentStock":
        return <CurrentStock back={backToMainMenu} />;
      case "discounts&offers":
        return <Discounts back={backToMainMenu} />;
      case "coupns":
        return <Coupons back={backToMainMenu} />;
      case "dashboards":
        return <Dashboards back={backToMainMenu} />;

      default:
        return <AddNewItem back={backToMainMenu} />;
    }
  };

  return (
    <div className="h-full mt-10 p-10 min-h-screen max-w-[1300px] w-full flex flex-col  items-center mb-10">
      {!menu ? (
        <div>
          <Button
            shape="circle"
            onClick={() => navigate("/profile")}
            icon={<ArrowLeftOutlined />}
            className="border-none"
          />
          <Breadcrumb className="self-start cursor-pointer text-lg">
            <Breadcrumb.Item>Admin Menu</Breadcrumb.Item>
          </Breadcrumb>
          <div class="grid mt-5 grid-cols-1 sm:grid-cols-2 w-full md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 gap-4">
            {menuItems?.map((item, index) => (
              <div
                onClick={() => setMenu(item?.name)}
                className="grid  place-content-center  h-36 w-56 rounded-2xl shadow-lg hover:bg-[#8785850c] cursor-pointer hover:shadow-3xl border-black"
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full">
          <Button
            shape="circle"
            onClick={() => setMenu("")}
            icon={<ArrowLeftOutlined />}
            className="border-none"
          />

          <ContentToShhow />
        </div>
      )}
    </div>
  );
}

export default Admin;
