import React, { useEffect, useState } from "react";
import searchIcon from "../assets/icons/search.png";
import rightArrowIcon from "../assets/icons/rightArrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSpring, animated, useTrail, a } from "@react-spring/web";
import logo from "../assets//logo.png";
import { CloseOutlined } from "@ant-design/icons";
import { Image } from "antd";

const Sidebar = ({ isOpen, closeSidebar }) => {
  const [prevPage, setPrevPage] = useState("main");
  const [menu, setMenu] = useState(prevPage);
  const location = useLocation();
  const navigate = useNavigate();
  const sidebarAnimation = useSpring({
    transform: isOpen ? "translateX(0%)" : "translateX(-100%)",
    config: { duration: 200 },
    opacity: 1,
  });

  useEffect(() => {
    if (location.pathname === "/") {
      setMenu("main");
    }
  }, []);
  const navigateTo = (path) => {
    navigate(path);
    closeSidebar();
  };

  const Trail = ({ open, children }) => {
    const items = React.Children.toArray(children);
    const trail = useTrail(items.length, {
      config: { mass: 5, tension: 2000, friction: 200 },
      opacity: open ? 1 : 0,
      x: open ? 0 : 20,
      height: open ? "100%" : 0,
      from: { opacity: 0, x: 20, height: "0%" },
    });
    return (
      <div>
        {trail.map(({ height, ...style }, index) => (
          <a.div key={index} style={style}>
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    );
  };

  const listItems = [
    { title: "Suits" },
    { title: "Shirts" },
    { title: "Trousers" },
    { title: "Coats & Vests" },
    { title: "Jackets & Blazers" },
  ];

  const ListToShow = ({ menu }) => {
    switch (menu) {
      case "main":
        return (
          <ul className="text-[14px]">
            {listItems.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  if (item?.subItems) {
                    setMenu(item?.title);
                  } else {
                    navigateTo(`explore/${item?.title}`);
                  }
                }}
                className="w-full hover:bg-[#f6f6f6] px-3 py-2 rounded-md mb-3 font-200 flex justify-between cursor-pointer "
              >
                {item.title}

                {item?.subItems && (
                  <Image
                    preview={false}
                    onClick={() => setMenu(item?.title)}
                    src={rightArrowIcon}
                    alt="rightArrow-icon"
                    style={{ filter: "invert(50%)" }}
                    className=" cursor-pointer  "
                  />
                )}
              </li>
            ))}
          </ul>
        );
      case "search":
        return (
          <Trail open={true}>
            <div className="flex border-b-2 p-2 gap-2 w-full">
              <Image
                preview={false}
                src={searchIcon}
                alt="search-icon"
                onClick={() => setMenu("search")}
                className=" cursor-pointer "
              />
              <input
                type="text"
                className="border-none focus:border-none focus:ring-0 w-full"
                placeholder="Search, suits, coats, etc"
              />
            </div>
          </Trail>
        );
    }
  };

  return (
    <>
      {isOpen && (
        <div
          onClick={() => {
            setMenu("main");
            closeSidebar();
          }}
          className="fixed inset-0 bg-black opacity-50 z-50"
        ></div>
      )}

      <animated.div
        style={sidebarAnimation}
        className={`fixed top-0 left-0 h-full p-8 w-full md:w-1/4 z-50 bg-white shadow-lg ${
          isOpen ? "overflow-y-auto" : ""
        }`}
      >
        <div className="flex items-center mb-8 w-full justify-between">
          {/* {menu === "main" ? (
            //    <Image
preview={false}
            //   src={searchIcon}
            //   alt="search-icon"
            //   onClick={() => setMenu("search")}
            //   className=" cursor-pointer "
            // />
            <div></div>
          ) : (
               <Image
preview={false}
              src={arrowBackIcon}
              alt="backArrow-icon"
              onClick={() => {
                setMenu(prevPage);
                setPrevPage("main");
              }}
              className=" cursor-pointer "
            />
          )} */}

          <img
            preview={false}
            src={logo}
            alt="bag-icon"
            className="h-[26px] w-auto"
          />

          <CloseOutlined
            style={{ color: "#000" }}
            onClick={() => {
              setMenu("main");
              closeSidebar();
            }}
            className=" cursor-pointer "
          />
        </div>

        <div className="w-full overflow-auto mt-10 ">
          <ListToShow menu={menu} />
        </div>
      </animated.div>
    </>
  );
};

export default Sidebar;
