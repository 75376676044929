import { Breadcrumb, Form, message } from "antd";
import React, { useState } from "react";
import ProductForm from "../../component/shared/ProductForm";
import api from "../../api";
import Loader from "../../component/shared/Loader";
function AddNewItem({ back }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const addNewItem = async (data) => {
    setLoading(true);

    const formData = new FormData();
    data.images.forEach((image) => {
      formData.append(`thumbnail`, image.originFileObj);
    });

    formData.append("name", data?.itemName);
    formData.append("description", data?.description);
    formData.append("price", data?.price);
    formData.append("category", data?.itemType);
    if (data?.itemType === "Suits") {
      if (data?.linkedItems?.length > 0) {
        formData.append(
          "linkedItems",
          data?.linkedItems?.map((item) => item.item)
        );
      }
    }

    try {
      const response = await api.post("/items/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        form.resetFields();
        messageApi.open({
          type: "success",
          content: response.data.message,
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Something went wrong!",
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error?.response?.data?.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-5 w-full self-start ">
      <Loader loading={loading} />
      {contextHolder}
      <Breadcrumb className="cursor-pointer text-lg">
        <Breadcrumb.Item onClick={back}>Admin Menu</Breadcrumb.Item>
        <Breadcrumb.Item>Add New Item</Breadcrumb.Item>
      </Breadcrumb>
      <div className="max-w-20 ">
        <ProductForm addItem={addNewItem} form={form} />
      </div>
    </div>
  );
}

export default AddNewItem;
