import React from "react";
import { services } from "./const";

function Services() {
  return (
    <div>
      {services?.map((service) => (
        <div
          onClick={service?.onclick}
          className="flex items-center  gap-5 cursor-pointer p-3  border-[0.5px]"
        >
          {service.icon}
          <div className="">
            <h1 className="text-[14px] text-stone-600">{service.name}</h1>
            <p className="text-[12px] text-stone-600">{service.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Services;
