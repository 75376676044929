import { FileImageOutlined } from "@ant-design/icons";
import React from "react";

function NoData() {
  return (
    <div className="h-full min-h-[300px] w-full grid place-items-center ">
      <p className="text-4xl text-stone-200">
        <FileImageOutlined /> No Data found
      </p>
    </div>
  );
}

export default NoData;
