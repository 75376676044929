import React from "react";
import closeIcon from "../../assets/icons/close.png";
import plusIcon from "../../assets/icons/plus.png";
import { useSpring, animated } from "@react-spring/web";
import { Image } from "antd";

const Filters = ({ open, close }) => {
  const sidebarAnimation = useSpring({
    transform: open ? "translateX(0%)" : "translateX(-100%)",
    config: { duration: 200 },
    opacity: 1,
  });
  const filters = [
    { show: false, title: "Sort by" },
    { show: false, title: "Color" },
    { show: false, title: "Size" },
    { show: false, title: "Price" },
    { show: false, title: "Model" },
    { show: false, title: "Material" },
    { show: false, title: "Clouser" },
    { show: false, title: "Season" },
    { show: false, title: "Woven by" },
  ];

  return (
    <>
      {open && (
        <div
          onClick={() => {
            close();
          }}
          className="fixed inset-0 bg-black opacity-50 z-30"
        ></div>
      )}
      <animated.div
        style={sidebarAnimation}
        className={`fixed top-0 left-0 h-screen  w-full md:w-1/4 bg-white z-50 shadow-lg `}
      >
        <Image
          preview={false}
          src={closeIcon}
          alt="close-icon"
          onClick={() => {
            close();
          }}
          className="absolute cursor-pointer  top-5 right-5 "
        />
        <div className="w-full my-16 text-2xl text-center  ">
          Filters & Sort
        </div>
        <ul className="text-[14px] overflow-y-scroll h-full w-full my-5 ">
          {filters.length &&
            filters.map((filter, index) => (
              <li
                key={index}
                className="w-full border-b-[1px] px-10 py-2 mb-6 font-200 flex justify-between cursor-pointer "
              >
                {filter?.title}
                <Image
                  preview={false}
                  src={plusIcon}
                  alt="plus-icon"
                  style={{ filter: "invert(50%)" }}
                  className=" cursor-pointer  "
                />
              </li>
            ))}
        </ul>

        <div className="flex absolute bottom-0 left-0 bg-white border-t-[1px] py-8 items-center justify-center w-full">
          <div>
            <button className="border-[1px] text-sm mr-5 py-2 px-10 rounded">
              Clear All
            </button>
            <button className="border-[1px] text-sm border-black text-white bg-black  py-2 rounded px-10 ">
              Apply
            </button>
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default Filters;
