import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import ArticleCard from "../component/shared/ArticleCard";
import Filters from "../component/shared/Filters";
import api from "../api";
import NoData from "../component/shared/NoData";
import Tabs from "../component/shared/Tabs";
import { LoadingOutlined } from "@ant-design/icons";
import Loader from "../component/shared/Loader";
import BackButton from "../component/shared/BackButton";

const mapItemData = (items) => {
  return items.map((item) => ({
    thumbnail: item?.thumbnails[0],
    id: item?._id,
    title: item?.name,
    type: item?.category,
    price: item?.price,
    description: item?.description,
  }));
};

function Explore() {
  const { item } = useParams();
  const { pathname } = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const showDetail = (itemId) => {
    navigate(`detail/${itemId}`);
  };

  useEffect(() => {
    const getList = async (category) => {
      setLoading(true);

      try {
        const response = await api.get(`/items/category/${category}`);
        if (response.status === 200) {
          const temp = mapItemData(response?.data?.items || []);
          setData(temp);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getList(item);
  }, [item]);
  return (
    <>
      <Loader loading={loading} />
      <div className="w-full p-5 min-h-screen flex items-start flex-col gap-10 ">
        <div className="flex gap-2 items-center ">
          <BackButton />
          <p className="text-2xl  text-stone font-extrabold ">{item}</p>
        </div>
        <Tabs />

        {data?.length > 0 ? (
          <div className="grid h-full  w-full md:grid-cols-2 lg:grid-cols-3 xsm:grid-cols-1 sm:grid-cols-1 gap-2">
            {data?.map((article, index) => (
              <div key={index} className="h-auto px-3 mb-2   w-full">
                <ArticleCard
                  key={index}
                  onClick={() => showDetail(article.id)}
                  article={article}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoData />
        )}
      </div>

      <Filters open={open} close={() => setOpen(false)} />
    </>
  );
}

export default Explore;
