import React, { useEffect, useState } from "react";
import { getImageType } from "./ImageChecker";
import { Image } from "antd";

function DashboardReplica({ dashboardImages }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImageDetails = async () => {
      const temp = await Promise.all(
        dashboardImages.map(async (imageUrl) => {
          try {
            const type = await getImageType(imageUrl);
            return { url: imageUrl, type };
          } catch (error) {
            console.error(`Error loading image: ${imageUrl}`);
            return null;
          }
        })
      );
      setImages(temp.filter((image) => image !== null));
    };

    fetchImageDetails();
  }, [dashboardImages]);

  const imageStyle = {
    width: "100%",
    height: "auto",
    display: "block",
  };

  return (
    <div className="w-full overflow-auto border-[1px] rounded">
      <div className="h-8 flex items-center w-full justify-center bg-white">
        <p className="text-sm text-dlack font-bold">SARTS</p>
      </div>
      <div className=" flex flex-col w-full h-full gap-0">
        {images.map((image, index) => (
          <div key={index} className="p-0">
            <Image
              preview={false}
              src={image.url}
              alt={`Image ${index}`}
              style={imageStyle}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardReplica;
