import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import Services from "./Services";

const StyleExpert = ({ open, close }) => {
  const sidebarAnimation = useSpring({
    transform: open ? "translateX(0%)" : "translateX(100%)",
    config: { duration: 200 },
    opacity: 1,
  });

  return (
    <>
      {open && (
        <div
          onClick={() => {
            close();
          }}
          className="fixed inset-0 bg-black opacity-50 z-40"
        ></div>
      )}

      <animated.div
        style={sidebarAnimation}
        className={`fixed top-0 right-0 h-full p-8 w-full md:w-1/4 bg-white z-50 shadow-lg ${
          open ? "overflow-y-auto" : ""
        }`}
      >
        <div className="flex items-center mb-8 justify-between">
          <CloseOutlined
            style={{ color: "#000" }}
            onClick={close}
            className=" cursor-pointer "
          />
        </div>

        <div className="w-full overflow-auto mt-10 flex flex-col  gap-3 ">
          <Avatar className="self-center" size={90} icon={<UserOutlined />} />

          <p className="text-stone-700 text-center text-xl font-bold my-5">
            We're here to help
          </p>
          <Services />
        </div>
      </animated.div>
    </>
  );
};

export default StyleExpert;
