import React, { useEffect, useState } from "react";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Profile from "./pages/Profile";
import MyBag from "./pages/MyBag";
import Explore from "./pages/Explore";
import Detail from "./pages/Detail";
import { ConfigProvider } from "antd";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Checkout from "./pages/Checkout";
import Admin from "./pages/Admin";
import Support from "./pages/Support";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypalClientId } from "./component/constants";
import { Provider } from "react-redux";
import store from "./store/rootReducer";
import NotFound from "./component/NotFound";

function App() {
  // const [showNavBar, setShowNavBar] = useState(true);
  // const [lastScrollY, setLastScrollY] = useState(0);

  // const controlNavbar = () => {
  //   if (window.scrollY > lastScrollY) {
  //     setShowNavBar(false);
  //   } else {
  //     setShowNavBar(true);
  //   }
  //   setLastScrollY(window.scrollY);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", controlNavbar);
  //   return () => {
  //     window.removeEventListener("scroll", controlNavbar);
  //   };
  // }, [lastScrollY]);

  return (
    <Provider store={store}>
      <PayPalScriptProvider
        options={{
          "client-id": paypalClientId,
        }}
      >
        <ConfigProvider theme={{ token: { colorPrimary: "#36454F" } }}>
          <Router>
            {/* <Navbar show={showNavBar} /> */}
            <Navbar />
            <div className="mt-20">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/support" element={<Support />} />
                <Route path="/profile/admin" element={<Admin />} />
                <Route path="/explore/:item" element={<Explore />} />
                <Route path="/explore/:item/detail/:id" element={<Detail />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/my-bag" element={<MyBag />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <Footer />
          </Router>
        </ConfigProvider>
      </PayPalScriptProvider>
    </Provider>
  );
}

export default App;
