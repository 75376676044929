import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Dropdown,
  Popconfirm,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api";
import Loader from "../../component/shared/Loader";

function Coupons({ back }) {
  const [open, setOpen] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getCoupons();
  }, []);
  const [form] = Form.useForm();
  const getCoupons = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/coupons/getAll`);
      if (response.status === 200) {
        const temp = response?.data?.map((item) => {
          return {
            name: item?.name,
            discount: item?.discount,
            expiryDate: item?.expiryDate,
            id: item?._id,
          };
        });
        setCoupons(temp);
      }
    } catch (error) {
      messageApi.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };
  const deleteCoupons = async (id) => {
    setLoading(true);
    try {
      const response = await api.delete(`/coupons/delete/${id}`);
      if (response.status === 200) {
        message.success(response?.data?.message);
        getCoupons();
      }
    } catch (error) {
      messageApi.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };
  const onFinish = async (data) => {
    const newCoupons = {
      name: data?.name,
      discount: data?.discount,
      expiryDate: data?.expiryDate,
    };

    try {
      const response = await api.post(`/coupons/create`, newCoupons);
      if (response.status === 201) {
        getCoupons();
        message.success("Coupon Added Successfully!");
        form.resetFields();
      } else {
        message.error("Something went wrong!");
      }
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  const Card = ({ name = "GIFT VOUCHER", discount = 0, onDelete, id }) => {
    const [showEditBtns, setshowEditBtns] = useState(false);

    return (
      <div className="overflow-hidden flex shadow-lg h-36 sm:w-[350px] xsm:w-[300px] bg-[linear-gradient(45deg,transparent_25%,rgba(68,68,68,.2)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_100%] bg-[position:-100%_0,0_0] bg-no-repeat  transition-[background-position_0s_ease] hover:bg-[position:200%_0,0_0] hover:duration-[1500ms]">
        <div className="w-1/4 h-full flex items-center justify-center bg-[#000000de]  ">
          <p className="text-2xl font-bold text-white -rotate-90">COUPON</p>
        </div>
        <div
          onMouseEnter={() => setshowEditBtns(!showEditBtns)}
          onMouseLeave={() => setshowEditBtns(!showEditBtns)}
          className="w-3/4 h-full relative px-10 justify-center bg-[#7c79790c] border-l-2 border-dashed border-black flex items-center flex-col"
        >
          {showEditBtns && (
            <div className="flex flex-col absolute right-5 top-5 gap-2">
              {/* <EditOutlined style={{ color: "#7c7979", cursor: "pointer" }} /> */}
              <Popconfirm
                okType="default"
                placement="rightTop"
                onConfirm={() => {
                  onDelete(id);
                }}
                title="Delete Coupon"
                description="Are you sure to delete?"
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{ color: "#7c7979", cursor: "pointer" }}
                />
              </Popconfirm>
            </div>
          )}
          <p className="font-bold uppercase text-center text-[14px] tracking-widest ">
            {name}
          </p>
          <div className="flex ">
            <p className="text-7xl font-bold ">{discount}</p>
            <div className="flex flex-col">
              <p className="text-4xl font-bold ">%</p>
              <p className="text-lg font-bold ">OFF</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-5 w-full self-start ">
      <Loader loading={loading} />
      {contextHolder}
      <Breadcrumb className="cursor-pointer text-lg">
        <Breadcrumb.Item onClick={back}>Admin Menu</Breadcrumb.Item>
        <Breadcrumb.Item>Coupons</Breadcrumb.Item>
      </Breadcrumb>
      <button
        onClick={() => setOpen(true)}
        className="border-[1px] text-[12px] border-black sm:self-end xsm:self-start text-white bg-black  py-1 rounded px-3 "
      >
        Add Coupon
      </button>

      <div className="max-w-20 flex flex-col gap-10 ">
        {coupons?.map(({ name, discount, id }, index) => (
          <Card
            name={name}
            index={index}
            discount={discount}
            id={id}
            onDelete={deleteCoupons}
          />
        ))}
      </div>
      <Modal
        title="Add Coupon"
        open={open}
        footer={[]}
        onCancel={() => setOpen(false)}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter Name!",
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>

          <Form.Item
            name="discount"
            rules={[
              {
                required: true,
                message: "Please enter Discount!",
              },
            ]}
          >
            <Input placeholder="Discount" size="large" />
          </Form.Item>
          <Form.Item
            name="expiryDate"
            rules={[
              {
                required: true,
                message: "Please enter Expiry Date!",
              },
            ]}
          >
            <DatePicker
              placeholder="Expiry Date"
              className="w-full"
              size="large"
            />
          </Form.Item>
          <button
            htmlType="submit"
            onClick={() => setOpen(false)}
            className="border-[1px] text-sm border-black text-white bg-black  py-2 rounded px-10 "
          >
            Save
          </button>
        </Form>
      </Modal>
    </div>
  );
}

export default Coupons;
