import React, { useEffect, useState } from "react";
import { animated } from "@react-spring/web";
import SizeChart from "./SizeChart";
import { sizeData } from "../const";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const SelectSize = ({ open, item, addToBag }) => {
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [rows, setRows] = useState([]);
  const myBag = useSelector((state) => state?.bag);
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedSize, setSelectedSize] = useState(null);

  useEffect(() => {
    setRows(sizeData);
  }, [open]);

  const selectSize = (size, name, index) => {
    setSelectedSize(size);
    const temp = rows.map((item) => ({
      ...item,
      regular: { ...item.regular, selected: false },
      long: { ...item.long, selected: false },
      short: { ...item.short, selected: false },
    }));

    const previousValue = temp[index][name].selected;
    temp[index][name].selected = !previousValue;
    setRows(temp);
  };

  return (
    <>
      {showSizeChart ? (
        <SizeChart close={() => setShowSizeChart(!showSizeChart)} />
      ) : (
        <div className=" p-4 md:p-5">
          <div className="w-full  text-2xl text-nowrap text-center">
            Select your size
          </div>
          <div className="w-full mt-5">
            <table className="w-full">
              <thead>
                <tr className="flex justify-around items-center gap3 my-5 md:my-10">
                  <th className="w-full"></th>
                  <th className="flex flex-col items-center w-full text-gray-500">
                    <div className="text-xs md:text-sm">Regular</div>
                    <div className="text-[8px] md:text-xs">108 - 186 cm</div>
                  </th>
                  <th className="flex flex-col items-center w-full text-gray-500">
                    <div className="text-xs md:text-sm">Long</div>
                    <div className="text-[8px] md:text-xs">{">186 cm"}</div>
                  </th>
                  <th className="flex flex-col items-center w-full text-gray-500">
                    <div className="text-xs md:text-sm">Short</div>
                    <div className="text-[8px] md:text-xs">{"< 168 cm"}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((item, index) => (
                  <tr
                    key={index}
                    className="flex justify-around gap-3 items-center mb-3"
                  >
                    <td className="text-gray-500 text-center text-[11px] w-full">
                      {item?.title}
                    </td>
                    <td
                      onClick={() =>
                        selectSize(item?.regular.size, "regular", index)
                      }
                      className={`  rounded p-2 flex items-center justify-center text-center cursor-pointer w-full ${
                        !item?.regular.avalaible
                          ? "bg-white text-gray-400 cursor-not-allowed"
                          : item?.regular.selected
                          ? "bg-black text-white"
                          : "bg-white"
                      }`}
                    >
                      {item?.regular.size}
                    </td>
                    <td
                      onClick={() => selectSize(item?.long.size, "long", index)}
                      className={` rounded p-2 flex items-center justify-center text-center cursor-pointer w-full ${
                        !item?.long.avalaible
                          ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                          : item?.long.selected
                          ? "bg-black text-white"
                          : "bg-white"
                      }`}
                    >
                      {item?.long.size}
                    </td>
                    <td
                      onClick={() =>
                        selectSize(item?.short.size, "short", index)
                      }
                      className={` rounded p-2 flex items-center justify-center text-center cursor-pointer w-full ${
                        !item?.short.avalaible
                          ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                          : item?.short.selected
                          ? "bg-black text-white"
                          : "bg-white"
                      }`}
                    >
                      {item?.short.size}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full mt-5 flex items-center gap-2 ">
            <button
              className={`w-full py-2 rounded grid place-items-center no-wrap text-sm bg-white 
              `}
              onClick={() => setShowSizeChart(!showSizeChart)}
            >
              Size chart
            </button>
            {myBag?.length > 0 && (
              <button
                disabled={!myBag?.length > 0}
                onClick={() => navigate("/my-bag")}
                className={`w-full py-2 rounded grid place-items-center no-wrap text-sm bg-white  
                `}
              >
                Go to bag
              </button>
            )}
            <button
              disabled={!selectedSize}
              onClick={() => {
                addToBag(selectedSize);
              }}
              className={`w-full py-2 rounded grid place-items-center text-sm ${
                selectedSize
                  ? "bg-black text-white cursor-pointer"
                  : "bg-stone-200 text-stone-400 cursor-not-allowed"
              }`}
            >
              Add to bag
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectSize;
