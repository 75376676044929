import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useDispatch } from "react-redux";
import Loader from "../component/shared/Loader";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(true);

  const handleAuthAction = async () => {
    setLoading(true);

    if (isLoginMode) {
      // Perform login
      try {
        const response = await api.post("user/login", { email, password });
        dispatch({ type: "SET_USER", payload: response.data.user });
        navigate("/");
        message.success("Login successful!");
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message.error(error.response.data.message);
        } else {
          console.error("Login failed:", error);
          message.error("Login failed. Please try again.");
        }
      }
    } else {
      // Perform sign up
      try {
        const response = await api.post("user/register", {
          fullName,
          email,
          phoneNo,
          password,
        });
        dispatch({ type: "SET_USER", payload: response.data.user });
        navigate("/");
        message.success("Sign up successful!");
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message.error(error.response.data.message);
        } else {
          console.error("Login failed:", error);
          message.error("Login failed. Please try again.");
        }
      }
    }
    setLoading(false);
  };

  const toggleMode = () => {
    setIsLoginMode((prevMode) => !prevMode);
    // Clear input fields when toggling between login and sign up mode
    setFullName("");
    setEmail("");
    setPhoneNo("");
    setPassword("");
    setConfirmPassword("");
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevVisible) => !prevVisible);
  };

  const isPasswordMatch = password === confirmPassword;
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isSignUpValid =
    isPasswordMatch && password && email && fullName && phoneNo;
  const isLoginValid = email && password;

  return (
    <div className="h-full min-h-screen w-full flex flex-col gap-20 items-center mb-10">
      <Loader loading={loading} />
      <div className="flex flex-col min-w-[300px] items-center gap-2 mt-40 ">
        <h2 className="text-black mb-10 text-2xl  ">
          {isLoginMode ? "Log In" : "Sign Up"}
        </h2>
        {!isLoginMode && (
          <input
            placeholder="Full Name"
            className="bg-white text-sm px-5 border-[1px] rounded h-12 w-full"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        )}
        <input
          placeholder="Email"
          type="email"
          className={`bg-white text-sm px-5 border-[1px] rounded h-12 w-full ${
            !isEmailValid && email ? "border-red-500" : ""
          }`}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!isLoginMode && (
          <input
            placeholder="Phone No"
            className="bg-white text-sm px-5 border-[1px] rounded h-12 w-full"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
          />
        )}
        <div className="relative w-full">
          <input
            placeholder="Password"
            type={isPasswordVisible ? "text" : "password"}
            className="bg-white text-sm px-5 border-[1px] rounded h-12 w-full pr-10"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="absolute top-0 right-0 h-full w-10 flex items-center justify-center"
            onClick={togglePasswordVisibility}
          >
            {isPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </button>
        </div>
        {!isLoginMode && (
          <>
            <input
              placeholder="Confirm Password"
              type="password"
              className={`bg-white text-sm px-5 border-[1px] rounded h-12 w-full ${
                isPasswordMatch || !confirmPassword ? "" : "border-red-500"
              }`}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {!isPasswordMatch && confirmPassword && (
              <p className="text-red-500 text-xs">Passwords do not match</p>
            )}
          </>
        )}
        <button
          className={`mt-10 border-[1px] text-sm rounded h-12 bg-black w-full text-white ${
            isLoginValid || isSignUpValid ? "" : "opacity-50 cursor-not-allowed"
          }`}
          onClick={handleAuthAction}
          disabled={!isLoginValid && !isSignUpValid}
        >
          {isLoginMode ? "Log In" : "Sign Up"}
        </button>
        <button
          className="text-sm text-black pointer-cursor underline hover:text-blue-500 "
          onClick={toggleMode}
        >
          {isLoginMode ? "Don't have an account?" : "Already have an account?"}
        </button>
      </div>
    </div>
  );
}

export default Login;
