import React, { useEffect, useState } from "react";
import closeIcon from "../assets/icons/close.png";
import { Button, Image, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeItemFromBag } from "../store/bagItemReducer/actions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import BackButton from "../component/shared/BackButton";

function MyBag() {
  const [bagItems, setBagItems] = useState([]);
  const dispatch = useDispatch();
  const myBag = useSelector((state) => state?.bag);
  const { user } = useSelector((state) => state);
  useEffect(() => {
    fetchBagItems();
  }, [myBag]);
  const fetchBagItems = () => {
    const temp = myBag?.map((item) => {
      return {
        thumbnail: item?.thumbnails[0],
        qty: 1,
        size: item?.size,
        id: item?._id,
        price: item?.price,
        name: item?.name,
        delivery: "1-2 weeks",
      };
    });
    setBagItems(temp);
  };
  const navigate = useNavigate();
  const getTotal = () => {
    let totalPrice = 0;
    for (const item of bagItems) {
      totalPrice += item.price;
    }
    return totalPrice;
  };

  const removeItem = (productId, selectedSize) => {
    dispatch(removeItemFromBag({ productId, selectedSize }));
    fetchBagItems();
  };
  return (
    <div>
      <div className="h-full min-h-screen w-full flex flex-col gap-20 items-center mb-10">
        {!bagItems?.length ? (
          <div className="flex flex-col gap-5 mt-40 ">
            <h2 className="text-black  text-2xl ">Your bag is still empty</h2>
            <button
              className="bg-white  text-sm border-[1px] rounded h-12 hover:bg-black hover:text-white"
              onClick={() => navigate("/")}
            >
              Start shopping
            </button>
          </div>
        ) : (
          <>
            <div className="flex sm:w-[400px] xsm:w-[300px] lg:w-[700px] gap-2 items-center justify-between w-full mt-[80px]">
              <BackButton />
              <h2 className="text-black font-semibold  text-2xl">
                Shopping bag
              </h2>
            </div>
            <div className="flex gap-10 h-full lg:flex-row xl:flex-row md:flex-col-reverse   xsm:flex-col-reverse  sm:flex-col-reverse ">
              <div className="flex flex-col  overflow-auto h-full items-center sm:w-[400px] xsm:w-[300px] lg:w-[700px] ">
                {bagItems?.map((item, index) => (
                  <div
                    key={index}
                    className="flex w-full sm:mx-1 xsm:mx-1 mb-5 items-start xsm:flex-col lg:flex-row xl:flex-row md:flex-row  sm:flex-col sm:max-h-[500px]  xsm:max-h-[500px]  max-h-[200px] rounded bg-white shadow-md overflow-hidden"
                  >
                    <Image
                      preview={false}
                      src={item?.thumbnail}
                      alt="article image"
                      className=" lg:h-full sm:w-full xsm:w-full sm:overflow-hidden xsm:overflow-hidden lg:w-[120px] "
                    />
                    <div className="flex justify-between  xsm:w-full sm:w-full flex-col px-5 gap-5 h-full py-3 w-[500px]">
                      <div className="flex justify-between w-full">
                        <div className="flex flex-col gap-1">
                          <div className=" text-sm">{item.name}</div>
                          <p className="text-stone-400 text-[10px]">
                            Delivery: {item?.delivery}
                          </p>
                        </div>
                        <Image
                          preview={false}
                          src={closeIcon}
                          alt="closeIcon"
                          onClick={() => {
                            removeItem(item?.id, item?.size);
                          }}
                          className=" cursor-pointer h-4 "
                        />
                      </div>
                      <div className="flex justify-between w-full">
                        <div className="flex justify-between gap-10">
                          {/* <div className="flex gap-1">
                            <div className=" text-sm text-stone-500 ">Qty.</div>

                            <Select
                              size="small"
                              defaultValue={item.qty}
                              onChange={handleChange}
                              options={[
                                { value: 1, label: "1" },
                                { value: 2, label: "2" },
                                { value: 3, label: "3" },
                                { value: 4, label: "4" },
                                { value: 5, label: "5", disabled: true },
                              ]}
                            />
                          </div> */}
                          <div className=" text-sm text-stone-500 ">
                            Size {item?.size}
                          </div>
                        </div>
                        <div className=" text-lg">${item.price}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col lg:w-full sm:w-full xsm:w-full md:w-full gap-5 w-[350px]">
                <ul className="text-[12px]  w-full  ">
                  <li className="w-full text-stone-600  mb-1 flex justify-between text-[12px] ">
                    <p>Delivery costs</p>
                    <p>FREE</p>
                  </li>
                  <li className="w-full  flex justify-between ">
                    <div className="flex items-end">
                      <p className="text-xl font-semibold ">Total</p>
                      <span className="text-stone-400 ml-1 text-md font-normal ">
                        Incl. vat
                      </span>
                    </div>
                    <p className="text-lg font-semibold ">
                      ${getTotal().toLocaleString()}
                    </p>
                  </li>
                </ul>

                <div className="flex  w-full">
                  <div
                    onClick={() => {
                      if (!user?.token) {
                        navigate("/login");
                      } else {
                        navigate("/checkout");
                      }
                    }}
                    className="text-center border-[1px] w-full rounded  bg-black text-white p-2 cursor-pointer "
                  >
                    Checkout
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MyBag;
