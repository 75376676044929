export const openWhatsApp = (phoneNumber) => {
  const url = `https://wa.me/${phoneNumber}`;
  window.open(url, "_blank");
};
export const openDialer = (phoneNumber) => {
  const url = `tel:${phoneNumber}`;
  window.location.href = url;
};
export const openEmailClient = (email) => {
  const url = `mailto:${email}`;
  window.location.href = url;
};
export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert("Email address copied to clipboard!");
    })
    .catch((err) => {
      alert("Failed to copy text.");
    });
};

export const cmToInches = (cm) => (cm / 2.54).toFixed(1);
export const inchesToCm = (inches) => (inches * 2.54).toFixed(1);
