export const isImageSquare = (imageUrl) => {
  const img = new Image();
  img.src = imageUrl;
  return img.width === img.height;
};

export const getImageType = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const { width, height } = img;
      if (width === height) {
        resolve("square");
      } else if (width > height) {
        resolve("landscape");
      } else {
        resolve("portrait");
      }
    };
    img.onerror = () => {
      console.error("Error loading image");
      reject();
    };
    img.src = imageUrl;
  });
};
