import React, { useState, useEffect } from "react";
import bagIcon from "../assets/icons/bag.png";
import logo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import profileIcon from "../assets/icons/profile.png";
import menuIcon from "../assets/icons/menu.png";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { Avatar, Badge } from "antd";
const Navbar = ({ show }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [darkMode, setdarkMode] = useState(false);
  const [state, toggle] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const myBag = useSelector((state) => state?.bag);
  useEffect(() => {
    // if (location.pathname === "/") {
    //   setdarkMode(true);
    // } else {
    //   setdarkMode(false);
    // }
  }, [location.pathname]);

  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 800 },
  });
  // const props = useSpring({
  //   transform: show ? "translateY(0%)" : "translateY(-100%)",
  //   config: { duration: 500 },
  //   opacity: 1,
  // });
  return (
    <>
      <Sidebar
        isOpen={isSidebarOpen}
        closeSidebar={() => {
          setIsSidebarOpen(false);
        }}
      />
      {/* <animated.nav
        className={`bg-transparent backdrop-blur-sm
         w-full  h-20 fixed  top-0 left-0 right-0 z-40   flex p-5 items-center justify-between
      `}
        style={props}
      > */}
      <animated.nav
        className={`bg-white backdrop-blur-sm
         w-full  h-20 fixed  top-0 left-0 right-0 z-40 flex p-5 items-center justify-between
      `}
        // style={props}
      >
        <Avatar
          onClick={() => setIsSidebarOpen(true)}
          src={
            <img
              src={menuIcon}
              alt="bag-icon"
              className={`${
                darkMode ? "invert" : "invert-0"
              } text-white cursor-pointer`}
            />
          }
          size="small"
        />

        <img
          preview={false}
          style={{
            opacity: x.to({ range: [0, 1], output: [0.3] }),
            scale: x.to({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 2.75, 1],
              output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 2.03, 1],
            }),
          }}
          onClick={() => {
            toggle(!state);
            navigate("/");
          }}
          // className={` ${
          //   darkMode ? "text-white" : "text-black"
          // }  font-extrabold xsm:text-3xl  md:text-4xl cursor-pointer `}

          src={logo}
          alt="bag-icon"
          className="h-[26px] w-auto cursor-pointer"
        />

        <div className="flex gap-2 ">
          <Avatar
            onClick={() => {
              navigate("/profile");
            }}
            src={
              <img
                preview={false}
                src={profileIcon}
                alt="bag-icon"
                className={`${
                  darkMode ? "invert" : "invert-0"
                } text-white cursor-pointer`}
              />
            }
            size="small"
          />

          <Badge dot={myBag?.length > 0} size="small">
            <Avatar
              onClick={() => {
                navigate("/my-bag");
              }}
              src={
                <img
                  preview={false}
                  src={bagIcon}
                  alt="bag-icon"
                  className={`${
                    darkMode ? "invert" : "invert-0"
                  } text-white cursor-pointer`}
                />
              }
              size="small"
            />
          </Badge>
        </div>
      </animated.nav>
    </>
  );
};

export default Navbar;
