const bagItemsReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_TO_BAG":
      return [...state, action.payload];
    case "REMOVE_FROM_BAG":
      return state.filter(
        (item) =>
          !(item._id === action.payload.id && item.size === action.payload.size)
      );
    default:
      return state;
  }
};

export default bagItemsReducer;
