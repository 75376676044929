import React, { useState, useEffect, useRef } from "react";
import ArticleCard from "../component/shared/ArticleCard";
import { Carousel, Drawer, Image, Modal, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import api from "../api";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../component/shared/Loader";
import SelectSize from "../component/shared/SelectSize";
import { RightOutlined } from "@ant-design/icons";

function Detail() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [open, setOpen] = useState(null);
  const [itemToAddToBag, setItemToAddToBag] = useState();
  const [item, setItem] = useState();
  const [notificationAPI, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    notificationAPI[type]({
      message: "Item added to bag Sucessfully!",
    });
  };
  useEffect(() => {
    getItem();
    getList();
  }, [id]);
  const scrollToSection = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const getList = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/items/getAll`);
      if (response.status === 200) {
        const temp = response?.data?.items?.map((item) => {
          return {
            thumbnail: item?.images[0],
            id: item?._id,
            title: item?.name,
            type: item?.category,
            price: item?.price,
            description: item?.description,
          };
        });
        setData(temp);
      }
    } catch (error) {
      notificationAPI.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };
  const getItem = async (category) => {
    setLoading(true);

    try {
      const response = await api.get(`/items/${id}`);
      if (response.status === 200) {
        const item = response?.data;
        setItem({
          thumbnails: item?.thumbnails,
          name: item?.name,
          avaliableSizes: item?.sizes,
          price: item?.price,
          description: item?.description,
          category: item?.category,
          linkedItems: item?.linkedItems,
        });
      }
    } catch (error) {
      notificationAPI.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };
  const responsiveSettings = [
    {
      breakpoint: 768, // Breakpoint for small screens (adjust as needed)
      settings: {
        slidesToShow: 1, // Number of tiles to show on small screens
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024, // Breakpoint for medium screens (adjust as needed)
      settings: {
        slidesToShow: 3, // Number of tiles to show on medium screens
        slidesToScroll: 3,
      },
    },
  ];
  const addToBag = (selectedSize) => {
    const product = { ...itemToAddToBag, size: selectedSize };
    dispatch({ type: "ADD_TO_BAG", payload: product });
    openNotificationWithIcon("success");
  };
  return (
    <div
      ref={containerRef}
      className="h-screen flex flex-col overflow-auto w-screen"
    >
      <Loader loading={loading} />

      {contextHolder}
      <div className="h-full sm:flex-col xsm:flex-col md:flex-row flex w-full">
        <div className="md:w-3/5 sm:w-full xsm:w-full flex md:flex-col xsm:flex-row sm:flex-row   h-full overflow-auto bg-stone-200 ">
          {item?.thumbnails?.map((article) => (
            <Image
              preview={false}
              alt="article image"
              className="w-auto h-auto "
              src={article}
            />
          ))}
        </div>
        <div className="w-full max-w-md mx-auto mt-5 p-5 bg-white flex flex-col items-center justify-center md:flex-row md:max-w-2xl md:space-x-5">
          <div className="w-full flex max-w-[300px] flex-col gap-5">
            <div className="flex flex-col gap-1 w-full">
              <div className="flex justify-between w-full">
                <div className="text-lg">{item?.name}</div>
                <p className="text-gray-700 text-lg">${item?.price}</p>
              </div>
              <div className="w-full">
                <p className="text-gray-600 text-[12px]">{item?.description}</p>
              </div>
            </div>
            <div
              onClick={() => {
                if (item?.category === "Suits") {
                  setOpen("mixMatch");
                } else {
                  setOpen("selectSize");
                  setItemToAddToBag(item);
                }
              }}
              className="bg-black text-white border border-black rounded h-8 flex items-center justify-center cursor-pointer"
            >
              Select size
            </div>
          </div>
        </div>
      </div>
      <div className="md:p-10 sm:p-5 xsm:p-5 ">
        <div className=" text-2xl font-semibold ">You might also like</div>
        <div className="mt-10 mb-20 ">
          <Carousel
            slidesToShow={3}
            slidesToScroll={true}
            infinite
            draggable
            autoplay
            dotPosition="top"
            responsive={responsiveSettings}
          >
            {data?.map((article, index) => (
              <div key={index} className="h-auto px-3 mb-2   w-[400px]">
                <ArticleCard
                  article={article}
                  onClick={() => {
                    scrollToSection();
                    navigate(`/explore/${article?.type}/detail/${article?.id}`);
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <Drawer
        onClose={() => {
          setItemToAddToBag(null);
          setOpen(null);
        }}
        visible={open === "mixMatch"}
        width={500}
        style={{
          backgroundColor: "#f6f6f6",
        }}
        bodyStyle={{ padding: 0 }}
      >
        <div className="">
          <div className="flex flex-col p-5 justify-center items-center">
            <h2 className="font-bold text-xl p-0 mb-5">Mix & Match</h2>
            <p className="text-base">Pick your size for each individual item</p>
          </div>
          <div className="p-8 flex flex-col gap-3 overflow-auto">
            {item?.linkedItems?.map((el, i) => (
              <div
                key={i}
                className="h-full w-full flex items-center justify-center gap-2 flex-col"
              >
                <div className="w-full  rounded-lg p-6 shadow-lg flex flex-col gap-5">
                  <div className="flex flex-col gap-1 w-full">
                    <div className="  w-full">
                      <div className="text-lg">{el?.name}</div>
                      <p className="text-gray-700 text-lg">${el?.price}</p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setOpen("selectSize");
                      setItemToAddToBag(el);
                    }}
                    className="border-[0.7px] border-stone-300 justify-between px-2 text-black  rounded h-10 flex items-center  cursor-pointer"
                  >
                    Select size
                    <RightOutlined />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
      <Drawer
        width={500}
        style={{
          backgroundColor: "#f6f6f6",
        }}
        title="Size Chart"
        onClose={() => {
          if (item?.category === "Suits") {
            setOpen("mixMatch");
          } else {
            setOpen(null);
          }
          setItemToAddToBag(null);
        }}
        visible={open === "selectSize"}
        bodyStyle={{ padding: 0 }}
      >
        <div className="p-4">
          <SelectSize
            item={item}
            addToBag={addToBag}
            open={open === "selectSize"}
            close={() => {
              if (item?.category === "Suits") {
                setOpen("mixMatch");
              } else {
                setOpen(null);
              }
              setItemToAddToBag(null);
            }}
          />
        </div>
      </Drawer>
    </div>
  );
}

export default Detail;
