import { Breadcrumb, Button, Modal, Select, Spin, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import axiosApi from "../../api";
import ArticleCard from "../../component/shared/ArticleCard";
import NoData from "../../component/shared/NoData";
import Loader from "../../component/shared/Loader";
const { Option } = Select;
function Orders({ back }) {
  const [menu, setMenu] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Order Products",
      dataIndex: "orderProducts",
      key: "orderProducts",
      render: (text, record) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => showOrderDetails(record)}
        >
          View Products
        </span>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      sorter: (a, b) => a.orderId.localeCompare(b.customerName),
    },
    {
      title: "Customer Address",
      dataIndex: "customerAddress",
      key: "customerAddress",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Select
          style={{ width: 120 }}
          value={record.status}
          onChange={(value) => handleStatusChange(record, value)}
        >
          <Option value="Pending">Pending</Option>
          <Option value="Shipped">Shipped</Option>
          <Option value="Delivered">Delivered</Option>
        </Select>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      filters: [
        { text: "Pending", value: "Pending" },
        { text: "Shipped", value: "Shipped" },
        { text: "Delivered", value: "Delivered" },
      ],
      onFilter: (value, record) => record.status === value,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select status"
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys([value])}
          >
            <Option value="Pending">Pending</Option>
            <Option value="Shipped">Shipped</Option>
            <Option value="Delivered">Delivered</Option>
          </Select>
          <Button
            onClick={() => {
              confirm();
            }}
            style={{ marginTop: 8 }}
          >
            Apply
          </Button>
          <Button onClick={clearFilters} style={{ marginLeft: 8 }}>
            Reset
          </Button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getOrders();
  }, []);
  const addressInOneLine = (address) => {
    let lineOne = address ? address.addressLine1 : null;
    let city = address ? address.city : null;
    let country = address ? address.country : null;
    let postcode = address ? address.postcode : null;

    let parts = [lineOne, city, postcode, country].filter(Boolean);

    return parts.join(", ");
  };

  const getOrders = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(`/orders/getAll`);
      if (response.status === 200) {
        const items = response?.data;
        const temp = items?.map((item) => {
          return {
            orderId: item?.orderId,
            orderProducts: item?.products,
            customerName: item?.userInfo?.fullName,
            customerAddress: addressInOneLine(item?.userInfo?.address),
            status: item?.status || "Pending",
          };
        });
        setData(temp);
      }
    } catch (error) {
      messageApi.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };
  const showOrderDetails = async (record) => {
    setSelectedOrder(record);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      return await axiosApi.put(`/orders/update/${orderId}`, {
        status: newStatus,
      });
    } catch (error) {
      throw error;
    }
  };

  const handleStatusChange = async (record, newStatus) => {
    try {
      await updateOrderStatus(record.orderId, newStatus);
      messageApi.open({
        type: "success",
        content: "Order status updated successfully",
      });
      getOrders();
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Failed to update order status",
      });
    }
  };

  return (
    <div className="flex gap-5 flex-col w-full self-start ">
      {contextHolder}

      <Loader loading={loading} />
      <Breadcrumb className="cursor-pointer text-lg">
        <Breadcrumb.Item onClick={back}>Admin Menu</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            if (menu) {
              setMenu("");
            }
          }}
        >
          Orders
        </Breadcrumb.Item>
        {menu ? <Breadcrumb.Item>{menu}</Breadcrumb.Item> : null}
      </Breadcrumb>

      <div className="min-w-[500px] w-full overflow-auto">
        {data?.length > 0 ? (
          <div>
            <Table dataSource={data} columns={columns} />
            <Modal
              title={`Order Details - ${selectedOrder?.orderId}`}
              visible={modalVisible}
              onCancel={closeModal}
              footer={[
                <Button key="close" onClick={closeModal}>
                  Close
                </Button>,
              ]}
            >
              {selectedOrder && (
                <div>
                  {/* Render additional details for the selected order */}

                  <div
                    className={`mb-5 grid ${
                      selectedOrder?.orderProducts?.length === 1
                        ? "grid-cols-1"
                        : "grid-cols-2"
                    } gap-3`}
                  >
                    {selectedOrder?.orderProducts?.map((item) => (
                      <ArticleCard
                        article={{
                          thumbnail: item?.images[0],
                          price: item?.price,
                          description: item?.description,
                          title: item?.name,
                        }}
                        size={item?.size}
                      />
                    ))}
                  </div>
                  <p>Customer Name: {selectedOrder.customerName}</p>
                  <p>Customer Address: {selectedOrder.customerAddress}</p>
                  <p>Status: {selectedOrder.status}</p>
                  {/* Add more details as needed */}
                </div>
              )}
            </Modal>
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}

export default Orders;
