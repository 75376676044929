import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = ({ loading }) => {
  return (
    <>
      {loading && (
        <div
          className={`fixed top-0 z-50 left-0 w-full h-full flex items-center justify-center `}
        >
          <div className="absolute inset-0 bg-gray-300 backdrop-blur-md bg-opacity-50"></div>
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 42,

                  color: "white",
                }}
                spin
              />
            }
          />{" "}
        </div>
      )}
    </>
  );
};

export default Loader;
