import { CheckOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import whatsApp from "../assets/WhatsApp_icon.png.webp";
import customerCare from "../assets/customerCare.png";
import mail from "../assets/email_sent-removebg-preview.png";
import mainBg from "../assets/wp3646146.jpg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Image } from "antd";
function Support() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  return (
    <div className="w-full min-h-screen">
      <Image
        preview={false}
        src={mainBg}
        alt="image1"
        className="w-full h-64 object-cover"
      />
      <div className="p-10 w-full">
        <h1 className="text-lg text-black font-bold ">Support</h1>
        <div className="border-t-[0.5px] my-5 w-full h-full " />
        <div className=" py-10 border-black w-full ">
          <div>
            <h2 className="text-lg font font-semibold mb-3">
              Benifits of Account
            </h2>
            <p className="text-sm">
              <CheckOutlined /> Track and trace your orders, and review past
              purchases
            </p>
            <p className="text-sm">
              <CheckOutlined /> Register returns and exchanges and plan pick-ups
            </p>
            <p className="text-sm">
              <CheckOutlined /> Save your measurements, payment methods and
              addresses
            </p>
            <div className="flex items-center mt-5 gap-5">
              {!user && (
                <button
                  onClick={() => navigate("/login")}
                  className="text-sm border-[0.5px] px-6 py-2 rounded "
                >
                  Login or Create an account
                </button>
              )}
            </div>
          </div>
          <div className="border-t-[0.5px] my-5 w-full h-full " />
          <div className="grid grid-cols-2 mt-10 sm:flex-col gap-5">
            <div className="h-auto flex flex-col gap-3 w-full">
              <Image
                preview={false}
                src={customerCare}
                alt="icon"
                className="w-20 h-auto"
              />
              <h1 className="text-xl">Call us</h1>
              <p className="text-sm text-wrap">
                <span className="font-bold">437-431-0423</span> Free We are
                available from Monday till Sunday, 9:00 - 22:00 CET.
              </p>
            </div>
            <div className="h-auto flex flex-col gap-3 w-full">
              <Image
                preview={false}
                src={mail}
                alt="icon"
                className="w-20 h-auto"
              />
              <h1 className="text-xl">Email</h1>
              <p className="text-sm text-wrap">
                Send us a mail on{" "}
                <span className="font-bold">Infosarts@gmail.com</span> We will
                reply to you as soon as possible.
              </p>
            </div>
            <div className="h-auto flex flex-col gap-3 w-full">
              <Image
                preview={false}
                src={whatsApp}
                alt="icon"
                className="w-20 h-auto"
              />
              <h1 className="text-xl">Whatsapp</h1>
              <p className="text-sm text-wrap">
                Reach us on <span className="font-bold">437-431-0423</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
