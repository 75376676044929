import React, { useEffect, useState, useMemo, useRef } from "react";
import { animated } from "@react-spring/web";
import { Button, Card, Carousel, Radio, Switch, Table } from "antd";
import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { sizeConversions, sizeData } from "../const";
import Services from "../Services";

const SizeChart = ({ close }) => {
  const [size, setSize] = useState("regular");
  const [inCM, setInCM] = useState(true);
  const tableRef = useRef(null);
  const [carouselSettings, setCarouselSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    className: "custom-carousel",
    draggable: true,
    dotPosition: "top",
  });
  useEffect(() => {
    const updateSettings = () => {
      const width = window.innerWidth;
      if (width >= 510) {
        setCarouselSettings((prevSettings) => ({
          ...prevSettings,
          slidesToShow: 2,
          slidesToScroll: 2,
        }));
      } else {
        setCarouselSettings((prevSettings) => ({
          ...prevSettings,
          slidesToShow: 1,
          slidesToScroll: 1,
        }));
      }
    };

    updateSettings();
    window.addEventListener("resize", updateSettings);

    return () => {
      window.removeEventListener("resize", updateSettings);
    };
  }, []);
  const getSizeValue = (
    regularValueCM,
    regularValueIN,
    longValueCM,
    longValueIN,
    shortValueCM,
    shortValueIN
  ) => {
    if (size === "regular") return !inCM ? regularValueCM : regularValueIN;
    if (size === "long") return !inCM ? longValueCM : longValueIN;
    if (size === "short") return !inCM ? shortValueCM : shortValueIN;
    return null;
  };
  const data = useMemo(
    () => [
      {
        XXS: getSizeValue(47, 18.6, null, null, null, null),
        XS: getSizeValue(49, 19.2, null, null, null, null),
        S: getSizeValue(51, 20, 50.5, 19.8, 51, 20),
        M: getSizeValue(53, 20.8, 52.5, 20.6, 53, 20.8),
        "M/L": getSizeValue(55, 21.6, 54.5, 21.4, 55, 21.6),
        L: getSizeValue(57, 22.4, 56.5, 22.2, 57, 22.4),
        XL: getSizeValue(59, 23.2, 58.5, 23, 59, 23.2),
        XXL: getSizeValue(61, 24, 60.5, 23.8, 60.5, 24),
        "3XL": getSizeValue(63, 24.8, 62.5, 24.6, 63, 24.8),
        "4XL": getSizeValue(65, 25.6, 64.5, 25.4, 65, 25.6),
        key: 1,
        name: "Chest width",
      },
      {
        XXS: getSizeValue(41, 16.2, null, null, null, null),
        XS: getSizeValue(43, 17, null, null, null, null),
        S: getSizeValue(45, 17.8, 44, 17.4, 45, 17.8),
        M: getSizeValue(47, 18.6, 46, 18.2, 47, 18.6),
        "M/L": getSizeValue(49, 19.2, 48, 18.8, 49, 19.2),
        L: getSizeValue(51, 20, 50, 19.6, 51, 20),
        XL: getSizeValue(53, 20.8, 52, 20.4, 53, 20.8),
        XXL: getSizeValue(55, 21.6, 54, 21.2, 55, 21.6),
        "3XL": getSizeValue(57, 22.4, 56, 22, 57, 22.4),
        "4XL": getSizeValue(59, 23.2, 58, 22.8, 59, 23.2),
        key: 2,
        name: "Waist width",
      },
      {
        XXS: getSizeValue(42, 16.6, null, null, null, null),
        XS: getSizeValue(43, 17, null, null, null, null),
        S: getSizeValue(44, 17.4, 44, 17.4, 44, 17.4),
        M: getSizeValue(45, 17.8, 45, 17.8, 45, 17.8),
        "M/L": getSizeValue(46, 18.2, 46, 18.2, 46, 18.2),
        L: getSizeValue(47, 18.6, 47, 18.6, 47, 18.6),
        XL: getSizeValue(48, 18.8, 48, 18.8, 48, 18.8),
        XXL: getSizeValue(49, 19.2, 49, 19.2, 49, 19.2),
        "3XL": getSizeValue(50, 19.6, 50, 19.6, 50, 19.6),
        "4XL": getSizeValue(51, 20, 51, 20, 51, 20),
        key: 3,
        name: "Shoulder width",
      },
      {
        XXS: getSizeValue(58, 22.8, null, null, null, null),
        XS: getSizeValue(59, 23.2, null, null, null, null),
        S: getSizeValue(59.5, 23.4, 62, 24.4, 57, 22.4),
        M: getSizeValue(60.5, 23.8, 63, 24.8, 58, 22.2),
        "M/L": getSizeValue(61.5, 24.2, 64, 25.2, 59, 23.2),
        L: getSizeValue(62.5, 24.6, 65, 25.6, 60, 23.6),
        XL: getSizeValue(63.5, 25, 66, 26, 61, 24),
        XXL: getSizeValue(64, 25.2, 66.5, 26.2, 61.5, 24.2),
        "3XL": getSizeValue(64.5, 25.4, 67.5, 26.6, 62, 24.4),
        "4XL": getSizeValue(65, 25.6, 68.5, 27, 62.5, 24.6),
        key: 4,
        name: "Sleeve width",
      },
      {
        XXS: getSizeValue(70, 27.6, null, null, null, null),
        XS: getSizeValue(71, 28, null, null, null, null),
        S: getSizeValue(72, 28.4, 75, 29.6, 69, 27.2),
        M: getSizeValue(73, 28.8, 76, 30, 70, 27.6),
        "M/L": getSizeValue(74, 29.2, 77, 30.4, 71, 28),
        L: getSizeValue(75, 29.6, 78, 30.8, 72, 28.4),
        XL: getSizeValue(76, 30, 79, 31.2, 73, 28.8),
        XXL: getSizeValue(77, 30.4, 80, 31.4, 74, 29.2),
        "3XL": getSizeValue(78, 30.8, 81, 31.8, 75, 29.6),
        "4XL": getSizeValue(79, 31.2, 82, 32.2, 76, 30),
        key: 5,
        name: "Jacket width",
      },
    ],
    [size, inCM]
  );

  const legData = useMemo(
    () => [
      {
        XXS: getSizeValue(37, 14.4, null, null, null, null),
        XS: getSizeValue(39, 15.4, null, null, null, null),
        S: getSizeValue(41, 16.2, 40, 15.8, 41, 16.2),
        M: getSizeValue(43, 17, 42, 16.6, 43, 17),
        "M/L": getSizeValue(45, 17.8, 44, 17.4, 45, 17.8),
        L: getSizeValue(47, 18.6, 46, 18.2, 47, 18.6),
        XL: getSizeValue(49, 19.2, 48, 18.8, 49, 19.2),
        XXL: getSizeValue(51, 20, 50, 19.6, 51, 20),
        "3XL": getSizeValue(53, 20.8, 52, 20.4, 53, 20.8),
        "4XL": getSizeValue(55, 21.6, 54, 21.2, 55, 21.6),
        key: 1,
        name: "Waist width",
      },
      {
        XXS: getSizeValue(82.5, 32.4, null, null, null, null),
        XS: getSizeValue(83.5, 32.8, null, null, null, null),
        S: getSizeValue(84.5, 33.2, 88.5, 34.8, 80, 31.4),
        M: getSizeValue(85.5, 33.6, 89.5, 35.2, 81, 31.8),
        "M/L": getSizeValue(86.5, 34, 90.5, 35.6, 82, 32.2),
        L: getSizeValue(87.5, 34.4, 91.5, 36, 83, 32.6),
        XL: getSizeValue(88.5, 34.8, 92.5, 36.4, 84, 33),
        XXL: getSizeValue(89.5, 35.2, 93.5, 36.8, 85, 33.4),
        "3XL": getSizeValue(90.5, 35.6, 94.5, 37.2, 86, 33.8),
        "4XL": getSizeValue(91.5, 36, 95.5, 37.6, 87, 34.2),
        key: 2,
        name: "Inside leg",
      },
      {
        XXS: getSizeValue(24.5, 9.6, null, null, null, null),
        XS: getSizeValue(25.5, 10, null, null, null, null),
        S: getSizeValue(26.5, 10.4, 26, 10.2, 26.5, 10.4),
        M: getSizeValue(27, 10.6, 27, 10.6, 27, 10.6),
        "M/L": getSizeValue(28, 11, 28, 11, 28, 11),
        L: getSizeValue(29, 11.4, 29, 11.4, 29, 11.4),
        XL: getSizeValue(30, 11.8, 30, 11.8, 30, 11.8),
        XXL: getSizeValue(31, 12.2, 30.5, 12.2, 31, 12.2),
        "3XL": getSizeValue(31.5, 12.4, 31.5, 12.4, 31.5, 12.4),
        "4XL": getSizeValue(32.5, 12.8, 32.5, 12.8, 32.5, 12.8),
        key: 3,
        name: "Upper leg width",
      },
      {
        XXS: getSizeValue(17.5, 6.8, null, null, null, null),
        XS: getSizeValue(18, 7, null, null, null, null),
        S: getSizeValue(18.5, 7.2, 18.5, 7.2, 18.5, 7.2),
        M: getSizeValue(19, 7.4, 19, 7.4, 19, 7.4),
        "M/L": getSizeValue(45, 7.6, 45, 7.6, 45, 7.6),
        L: getSizeValue(20, 7.8, 20, 7.8, 20, 7.8),
        XL: getSizeValue(20.5, 8, 20.5, 8, 20.5, 8),
        XXL: getSizeValue(21, 8.2, 21, 8.2, 21, 8.2),
        "3XL": getSizeValue(21.5, 8.4, 21.5, 8.4, 21.5, 8.4),
        "4XL": getSizeValue(22, 8.6, 22, 8.6, 22, 8.6),
        key: 4,
        name: "Foot opening width",
      },
    ],
    [inCM]
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: (
          <Switch
            checkedChildren="in"
            unCheckedChildren="cm"
            checked={inCM}
            onChange={(checked) => setInCM(checked)}
          />
        ),
        dataIndex: "name",
        key: "name",
        fixed: "left",
      },
    ];

    const dynamicColumns = sizeData.map((item) => ({
      title: (
        <div className="flex flex-col text-stone-500 gap-1 items-center">
          <div className="text-[12px]">{item[size].size}</div>
          <div className="text-[8px]">{item.title}</div>
        </div>
      ),
      dataIndex: item.title,
      key: item.title,
    }));

    return [...baseColumns, ...dynamicColumns];
  }, [size, inCM]);
  const scrollLeft = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft -= 150; // Adjust scroll distance as needed
    }
  };

  const scrollRight = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft += 150; // Adjust scroll distance as needed
    }
  };
  return (
    <animated.div className="">
      <div className="flex items-center mt-5 justify-center">
        {/* <div className="w-full cursor-pointer" onClick={close}>
          <ArrowLeftOutlined />
        </div> */}
        <div className="w-full md:text-2xl text-center text-xl ">
          Size Chart
        </div>
      </div>
      <div className="w-full mt-5 text-2xl text-center">
        <Radio.Group
          size="large"
          value={size}
          onChange={(e) => setSize(e.target.value)}
        >
          <Radio.Button value="regular">
            <div className="flex flex-col text-stone-500 gap-1 items-center">
              <div className="text-[12px]">Regular</div>
              <div className="text-[8px]">108 - 186 cm</div>
            </div>
          </Radio.Button>
          <Radio.Button value="long">
            <div className="flex flex-col text-stone-500 gap-1 items-center">
              <div className="text-[12px]">Long</div>
              <div className="text-[8px]">{">186 cm"}</div>
            </div>
          </Radio.Button>
          <Radio.Button value="short">
            <div className="flex flex-col text-stone-500 gap-1 items-center">
              <div className="text-[12px]">Short</div>
              <div className="text-[8px]">{"< 168 cm"}</div>
            </div>
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="w-full mb-2 mt-10 text-xl">Jacket/Coat/Shirt</div>

      <Table
        columns={columns}
        dataSource={data}
        bordered
        size="middle"
        scroll={{ x: "calc(700px + 50%)" }}
        pagination={false}
      />
      <div className="w-full mb-2 mt-10 text-xl">Trouser</div>

      <Table
        columns={columns}
        dataSource={legData}
        bordered
        size="middle"
        scroll={{ x: "calc(700px + 50%)" }}
        pagination={false}
      />
      <div className="w-full mt-10 mb-2 text-xl">Size Conversions</div>

      <Carousel {...carouselSettings}>
        {sizeConversions.map((item, index) => (
          <div key={index}>
            <Card
              title={
                <div className="flex flex-col">
                  <div className="text-[14px] font-bold">{item.title}</div>
                  <div className="text-[10px]">{item?.subTitle}</div>
                </div>
              }
              style={{ width: 230 }}
            >
              {item.fields.map((field, fieldIndex) => (
                <div
                  key={fieldIndex}
                  className="flex items-center text-stone-500 mb-3 text-[14px] justify-between"
                >
                  <p>{field.key}</p>
                  <p className="text-left">{field.value}</p>
                </div>
              ))}
            </Card>
          </div>
        ))}
      </Carousel>
      <div className="mt-10 gap-2 flex flex-col ">
        <p>Still unsure about your size? Contact us at </p>
        <Services />
        <Button type="text" onClick={close}>
          Done
        </Button>
      </div>
    </animated.div>
  );
};

export default SizeChart;
