import {
  DeleteOutlined,
  PlusOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";

import {
  Breadcrumb,
  Modal,
  Form,
  Input,
  Popconfirm,
  message,
  Upload,
  Badge,
  Tooltip,
  Image,
} from "antd";
import NoData from "../../component/shared/NoData";
import React, { useEffect, useState } from "react";
import api from "../../api";
import DashboardReplica from "../../component/shared/DashboardReplica";
import Loader from "../../component/shared/Loader";

function Dashboards({ back }) {
  const [fileList, setFileList] = useState([]);
  const [open, setOpen] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const handleCancel = () => setPreviewOpen(false);

  const [form] = Form.useForm();
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  useEffect(() => {
    getDashboards();
  }, []);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getDashboards = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/dashboard/getAll`);
      if (response.status === 200) {
        const temp = response?.data?.map((item) => {
          return {
            name: item?.name,
            default: item?.default,
            images: item?.images,
            id: item?._id,
          };
        });
        setDashboards(temp);
      }
    } catch (error) {
      messageApi.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };
  const deleteDashboards = async (id) => {
    setLoading(true);
    try {
      const response = await api.delete(`/dashboard/delete/${id}`);
      if (response.status === 200) {
        message.success(response?.data?.message);
        getDashboards();
      }
    } catch (error) {
      messageApi.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };
  const setDefault = async (id) => {
    setLoading(true);
    try {
      const response = await api.put(`/dashboard/setDefault/${id}`);
      if (response.status === 200) {
        message.success(response?.data?.message);
        getDashboards();
      }
    } catch (error) {
      messageApi.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };
  const onFinish = async (data) => {
    setLoading(true);

    const formData = new FormData();
    fileList.forEach((image) => {
      formData.append(`dashboard`, image.originFileObj);
    });

    formData.append("name", data?.name);
    try {
      const response = await api.post("/dashboard/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        form.resetFields();
        messageApi.open({
          type: "success",
          content: response.data.message,
        });
        getDashboards();
      } else {
        messageApi.open({
          type: "error",
          content: "Something went wrong!",
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error?.response?.data?.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const Card = ({ name, images, onDelete, id, isDefault, setDefault }) => {
    const [showEditBtns, setshowEditBtns] = useState(false);

    return (
      <div className="overflow-hidden flex flex-col shadow-lg h-56 sm:w-[300px] xsm:w-[300px] bg-[linear-gradient(45deg,transparent_25%,rgba(68,68,68,.2)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_100%] bg-[position:-100%_0,0_0] bg-no-repeat  transition-[background-position_0s_ease] hover:bg-[position:200%_0,0_0] hover:duration-[1500ms]">
        <div
          onMouseEnter={() => setshowEditBtns(!showEditBtns)}
          onMouseLeave={() => setshowEditBtns(!showEditBtns)}
          className="w-full h-full relative   bg-[#7c79790c]  justify-between  flex items-center flex-col"
        >
          {showEditBtns && (
            <div className="flex flex-col absolute right-5 top-3 gap-2">
              {!isDefault && (
                <Tooltip title="Set as Default">
                  <CheckSquareOutlined
                    onClick={() => setDefault(id)}
                    style={{ color: "#7c7979", cursor: "pointer" }}
                  />
                </Tooltip>
              )}
              <Popconfirm
                okType="default"
                placement="rightTop"
                onConfirm={() => {
                  onDelete(id);
                }}
                title="Delete Dashboard"
                default="Are you sure to delete?"
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{ color: "#7c7979", cursor: "pointer" }}
                />
              </Popconfirm>
            </div>
          )}
          <DashboardReplica dashboardImages={images} />
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-5 w-full self-start ">
      <Loader loading={loading} />
      {contextHolder}
      <Breadcrumb className="cursor-pointer text-lg">
        <Breadcrumb.Item onClick={back}>Admin Menu</Breadcrumb.Item>
        <Breadcrumb.Item>Dashboards</Breadcrumb.Item>
      </Breadcrumb>
      <button
        onClick={() => setOpen(true)}
        className="border-[1px] text-[12px] border-black sm:self-end xsm:self-start text-white bg-black  py-1 rounded px-3 "
      >
        Add Dashboard
      </button>

      <div className="max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xsm:grid-cols-1  lg:grid-cols-3 gap-10">
        {dashboards?.length > 0 ? (
          dashboards?.map((item) => (
            <div key={item.id} className="w-full">
              {item.default ? (
                <Badge.Ribbon text="Default" color="red" placement="start">
                  <Card
                    name={item.name}
                    index={item.index}
                    isDefault={item.default}
                    id={item.id}
                    images={item.images}
                    onDelete={deleteDashboards}
                  />
                </Badge.Ribbon>
              ) : (
                <Card
                  name={item.name}
                  index={item.index}
                  isDefault={item.default}
                  id={item.id}
                  setDefault={setDefault}
                  images={item.images}
                  onDelete={deleteDashboards}
                />
              )}
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>

      <Modal
        title="Add Dashboard"
        open={open}
        footer={[]}
        onCancel={() => setOpen(false)}
      >
        <Upload
          className="mb-5"
          listType="picture-card"
          fileList={fileList}
          beforeUpload={(file) => {
            return false;
          }}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <Image
            preview={false}
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter Name!",
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>

          <button
            htmlType="submit"
            disabled={fileList.length === 0}
            onClick={() => setOpen(false)}
            className={`border-[1px] text-sm ${
              fileList?.length > 0
                ? "border-black bg-black"
                : "border-stone-300 cursor-not-allowed bg-stone-300"
            }  text-white py-2 rounded px-10 `}
          >
            Save
          </button>
        </Form>
      </Modal>
    </div>
  );
}

export default Dashboards;
