import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Dropdown,
  Popconfirm,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { useState } from "react";

function Discounts({ back }) {
  const tempdata = [
    {
      name: "GOLD",
      date: "23 June, 2024",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: 25,
    },
    {
      name: "SILVER",
      date: "23 June, 2024",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: 10,
    },
  ];
  const [open, setOpen] = useState(false);
  const [discounts, setDiscounts] = useState(tempdata);
  const OnEdit = () => {};
  const Card = ({ name, date, description, price, index }) => {
    const [showEditBtns, setshowEditBtns] = useState(false);

    return (
      <div className="overflow-hidden flex shadow-lg  rounded-3xl h-36 sm:w-[450px] xsm:w-[300px] bg-[linear-gradient(45deg,transparent_25%,rgba(68,68,68,.2)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_100%] bg-[position:-100%_0,0_0] bg-no-repeat  transition-[background-position_0s_ease] hover:bg-[position:200%_0,0_0] hover:duration-[1500ms]">
        <div className="w-1/4 h-full relative flex items-center justify-center bg-yellow-300  ">
          <p className="text-5xl font-bold text-white -rotate-90">${price}</p>
        </div>
        <div
          onMouseEnter={() => setshowEditBtns(!showEditBtns)}
          onMouseLeave={() => setshowEditBtns(!showEditBtns)}
          className="w-3/4 h-full relative px-10 justify-center gap-2 flex items-center flex-col border-l-2 border-black bg-[#7c79790c] border-dashed"
        >
          {showEditBtns && (
            <div className="flex flex-col absolute right-5 top-5 gap-2">
              <EditOutlined style={{ color: "#7c7979", cursor: "pointer" }} />
              <Popconfirm
                okType="default"
                placement="rightTop"
                onConfirm={() => {
                  const temp = [...discounts];
                  temp.splice(index, 1);
                  setDiscounts(temp);
                }}
                title="Delete Discount"
                description="Are you sure to delete?"
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{ color: "#7c7979", cursor: "pointer" }}
                />
              </Popconfirm>
            </div>
          )}
          <p className="text-5xl font-bold ">{name}</p>
          <p className="text-[10px] pb-0.5  border-b-[1px] border-black ">
            Valid untill{" "}
            <span className="font-bold">{moment(date).format("ll")}</span>
          </p>
          <p className="text-[8px] text-center  ">{description}</p>
        </div>
      </div>
    );
  };
  const onFinish = (data) => {
    const newDiscount = {
      name: data?.name,
      price: data?.price,
      date: data?.date,
      description: data?.description,
    };
    setDiscounts([...discounts, newDiscount]);
  };
  return (
    <div className="flex flex-col gap-5 w-full self-start ">
      <Breadcrumb className="cursor-pointer text-lg">
        <Breadcrumb.Item onClick={back}>Admin Menu</Breadcrumb.Item>
        <Breadcrumb.Item>Discounts & Offers</Breadcrumb.Item>
      </Breadcrumb>
      <button
        onClick={() => setOpen(true)}
        className="border-[1px] text-[12px] border-black sm:self-end xsm:self-start text-white bg-black  py-1 rounded px-3 "
      >
        Add Discount
      </button>

      <div className="max-w-20 flex flex-col gap-10 ">
        {discounts?.map(({ name, date, description, price }, index) => (
          <Card
            name={name}
            index={index}
            date={date}
            description={description}
            price={price}
          />
        ))}
      </div>
      <Modal
        title="Add Discount"
        open={open}
        footer={[]}
        onCancel={() => setOpen(false)}
      >
        <Form onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter Name!",
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>

          <Form.Item
            name="price"
            rules={[
              {
                required: true,
                message: "Please enter Price!",
              },
            ]}
          >
            <Input placeholder="Price" size="large" />
          </Form.Item>
          <Form.Item
            name="date"
            rules={[
              {
                required: true,
                message: "Please enter Date!",
              },
            ]}
          >
            <DatePicker size="large" className="w-full" />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter Description!",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Description" maxLength={100} />
          </Form.Item>

          <button
            htmlType="submit"
            onClick={() => setOpen(false)}
            className="border-[1px] text-sm border-black text-white bg-black  py-2 rounded px-10 "
          >
            Save
          </button>
        </Form>
      </Modal>
    </div>
  );
}

export default Discounts;
