import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Popconfirm,
  Space,
  Table,
  message,
  Modal,
  Spin,
  Form,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import api from "../../api";
import ProductForm from "../../component/shared/ProductForm";
import { update } from "@react-spring/web";
import ArticleCard from "../../component/shared/ArticleCard";
import Loader from "../../component/shared/Loader";
import { useNavigate } from "react-router-dom";

function CurrentStock({ back }) {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [idToUpdate, setIdToUpdate] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const getAllItems = async () => {
    setLoading(true);
    try {
      const response = await api.get("/items/getAll");
      if (response.status === 200) {
        const temp = response?.data?.items?.map((item) => {
          return {
            key: item?._id,
            name: item?.name,
            type: item?.category,
            images: item?.thumbnails,
            price: item?.price,
            description: item?.description,
            linkedItems: item?.linkedItems,
          };
        });
        setData(temp);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllItems();
  }, []);

  const updateItem = async () => {
    setLoading(true);

    const data = form.getFieldValue();
    const payload = {
      name: data?.itemName,
      description: data?.description,
      price: data?.price,
      category: data?.itemType,
    };
    try {
      const response = await api.put(`/items/update/${idToUpdate}`, payload);
      if (response.status === 200) {
        setIdToUpdate(null);
        setIsModalOpen(false);
        form.resetFields();
        getAllItems();
        message.success("Item Updated Successfully!");
      }
    } catch (error) {
      message.error("Something went wrong!");
    }
    setLoading(false);
  };

  const confirm = async (record) => {
    setLoading(true);
    try {
      await api.delete(`/items/delete/${record.key}`);
      getAllItems();
      message.success("Item Deleted Successfully!");
    } catch (error) {
      message.error("Something went wrong!");
    }
    setLoading(false);
  };

  const cancel = (e) => {
    // message.error("Click on No");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => showItemDetails(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              let dynamicValues = {
                itemName: record?.name,
                itemType: record?.type,
                price: record?.price,
                description: record?.description,
                images: record?.images,
              };
              form.setFieldsValue(dynamicValues);
              setIdToUpdate(record?.key);
              setIsModalOpen(true);
            }}
            className="cursor-pointer  "
            style={{ color: "gray" }}
          />
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this Item?"
            onConfirm={() => confirm(record)}
            onCancel={cancel}
            okType="default"
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              className="cursor-pointer  "
              style={{ color: "red" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const showItemDetails = async (record) => {
    setSelectedItem(record);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  return (
    <div className="flex gap-5 flex-col w-full self-start ">
      <Loader loading={loading} />

      <Breadcrumb className="cursor-pointer text-lg">
        <Breadcrumb.Item onClick={back}>Admin Menu</Breadcrumb.Item>
        <Breadcrumb.Item>Current Stock</Breadcrumb.Item>
      </Breadcrumb>
      <div className="min-w-[500px] w-full overflow-auto">
        <Table
          columns={columns}
          pagination={{
            position: "bottomRight",
          }}
          dataSource={data}
        />
        <Modal
          title={`Item Details - ${selectedItem?.name}`}
          visible={modalVisible}
          onCancel={closeModal}
          footer={[]}
        >
          {selectedItem && (
            <div>
              <ArticleCard
                onClick={() =>
                  navigate(
                    `/explore/${selectedItem?.type}/detail/${selectedItem?.key}`
                  )
                }
                article={{
                  thumbnail: selectedItem?.images[0],
                  price: selectedItem?.price,
                  description: selectedItem?.description,
                  title: selectedItem?.name,
                }}
              />
            </div>
          )}
        </Modal>
      </div>
      <Modal
        title="Edit Product Detail"
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        footer={[]}
      >
        <ProductForm addItem={updateItem} form={form} edit={true} />
      </Modal>
    </div>
  );
}

export default CurrentStock;
