import { Collapse, Form, Input, Select, Button, message, Image } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  MinusCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Modal, Upload } from "antd";
import api from "../../api";

function ProductForm({ addItem, form, edit = false }) {
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [existingItemsOpt, setExistingItemsOpt] = useState([]);
  const [previewTitle, setPreviewTitle] = useState("");
  const [itemType, setItemType] = useState("");
  const [linkedItems, setLinkedItems] = useState([]);
  const [price, setPrice] = useState(null);

  useEffect(() => {
    getAllItems();
  }, [addItem]);

  useEffect(() => {
    if (edit) {
      if (form.getFieldValue("type") === "Suits") {
        setLinkedItems(form.getFieldValue("linkedItems"));
      }
      setPrice(form.getFieldValue("price"));
      const filteredImages = form.getFieldValue("images")?.filter((url) => url);
      const temp = filteredImages?.map((url, index) => ({
        uid: index + 1,
        name: `Image ${index + 1}`,
        status: "done",
        url: url,
      }));
      setFileList(temp?.length > 0 ? temp : []);
    }
  }, [form, edit]);

  useEffect(() => {
    const totalPrice = linkedItems.reduce((sum, item) => {
      const price = Number(item.price) || 0;
      return sum + price;
    }, 0);
    setPrice(totalPrice);

    form.setFieldsValue({ price: totalPrice });
  }, [linkedItems, form]);

  const getAllItems = async () => {
    try {
      const response = await api.get("/items/getAll");
      if (response.status === 200) {
        const temp = response.data.items.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
          price: item.price,
        }));
        setExistingItemsOpt(temp);
      }
    } catch (error) {
      message.error(error.message || "Something went wrong");
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const itemTypes = [
    { value: "Suits", label: "Suits" },
    { value: "Trousers", label: "Trousers" },
    { value: "Coats & Vests", label: "Coats & Vests" },
    { value: "Shirts", label: "Shirts" },
    { value: "Jackets & Blazers", label: "Jackets & Blazers" },
  ];

  const handleAddLinkedItem = () => {
    setLinkedItems([...linkedItems, { key: Date.now(), item: null }]);
  };

  const handleLinkedItemChange = (value, index) => {
    const selectedItem = existingItemsOpt.find((item) => item.value === value);
    const updatedLinkedItems = linkedItems.map((item, i) =>
      i === index ? { ...item, item: value, price: selectedItem.price } : item
    );
    setLinkedItems(updatedLinkedItems);
  };

  const handleRemoveLinkedItem = (index) => {
    const updatedLinkedItems = linkedItems.filter((_, i) => i !== index);
    setLinkedItems(updatedLinkedItems);
  };

  const submit = async (data) => {
    try {
      data.images = edit ? fileList.map((file) => file.url) : fileList;
      data.linkedItems = linkedItems;
      await addItem(data);
      setExistingItemsOpt([]);
      setFileList([]);
    } catch (error) {
      message.error(error.message || "Something went wrong");
    }
  };

  return (
    <div className="w-full">
      <Upload
        className="mb-5"
        listType="picture-card"
        fileList={fileList}
        beforeUpload={(file) => false}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 12 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <Image
          preview={false}
          alt="example"
          style={{ width: "100%" }}
          src={previewImage}
        />
      </Modal>
      <Form
        className="w-full grid grid-cols-2 gap-2"
        form={form}
        onFinish={submit}
      >
        <Form.Item
          name="itemName"
          className="xsm:col-span-2 sm:col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1"
          rules={[{ required: true, message: "Please enter your Item name!" }]}
        >
          <Input placeholder="Item Name" size="large" />
        </Form.Item>
        <Form.Item
          className="xsm:col-span-2 sm:col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1"
          name="itemType"
          rules={[{ required: true, message: "Please enter Item Type!" }]}
        >
          <Select
            options={itemTypes}
            placeholder="Item Type"
            size="large"
            onChange={(value) => setItemType(value)}
          />
        </Form.Item>

        <Form.Item
          className="xsm:col-span-2 sm:col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1"
          name="description"
          rules={[
            { required: true, message: "Please enter Item Description!" },
          ]}
        >
          <Input placeholder="Description" size="large" />
        </Form.Item>
        <Form.Item
          className="xsm:col-span-2 sm:col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1"
          name="price"
          rules={[{ required: true, message: "Please enter Price!" }]}
        >
          <Input
            placeholder="Price"
            size="large"
            value={price}
            type="number"
            // readOnly={itemType === "Suits"}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Form.Item>
        {itemType === "Suits" && <p className="col-span-2">Linked Items:</p>}
        <div className="col-span-2">
          {itemType === "Suits" && (
            <>
              {linkedItems.map((linkedItem, index) => (
                <div key={linkedItem.key} className="flex items-center mb-3">
                  <Form.Item
                    className="flex-1"
                    rules={[
                      {
                        required: true,
                        message: "Please select an existing item!",
                      },
                    ]}
                  >
                    <Select
                      options={existingItemsOpt}
                      placeholder="Select Existing Item"
                      size="large"
                      onChange={(value) => handleLinkedItemChange(value, index)}
                    />
                  </Form.Item>
                  <Button
                    size="large"
                    className="ml-2 self-start"
                    onClick={() => handleRemoveLinkedItem(index)}
                    icon={<CloseOutlined />}
                  />
                </div>
              ))}
              <Button
                size="large"
                className="mb-3 w-full"
                onClick={handleAddLinkedItem}
              >
                <PlusOutlined />
                Another Existing Item
              </Button>
            </>
          )}
        </div>

        <button
          type="submit"
          disabled={fileList.length === 0}
          className={`border-[1px] text-sm ${
            fileList?.length > 0
              ? "border-black bg-black"
              : "border-stone-300 cursor-not-allowed bg-stone-300"
          }  text-white py-2 rounded px-10 `}
        >
          Save
        </button>
      </Form>
    </div>
  );
}

export default ProductForm;
