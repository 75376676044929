import { PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { openDialer, openWhatsApp } from "./functions";

export const sizeData = [
  {
    title: "XXS",
    regular: { size: 42, selected: false, avalaible: true },
    long: { size: 82, selected: false, avalaible: true },
    short: { size: 21, selected: false, avalaible: true },
  },
  {
    title: "XS",
    regular: { size: 44, selected: false, avalaible: true },
    long: { size: 86, selected: false, avalaible: true },
    short: { size: 22, selected: false, avalaible: true },
  },
  {
    title: "S",
    regular: { size: 46, selected: false, avalaible: true },
    long: { size: 90, selected: false, avalaible: true },
    short: { size: 23, selected: false, avalaible: true },
  },
  {
    title: "M",
    regular: { size: 48, selected: false, avalaible: true },
    long: { size: 94, selected: false, avalaible: true },
    short: { size: 24, selected: false, avalaible: true },
  },
  {
    title: "M/L",
    regular: { size: 50, selected: false, avalaible: true },
    long: { size: 98, selected: false, avalaible: true },
    short: { size: 25, selected: false, avalaible: true },
  },
  {
    title: "L",
    regular: { size: 52, selected: false, avalaible: true },
    long: { size: 102, selected: false, avalaible: true },
    short: { size: 26, selected: false, avalaible: true },
  },
  {
    title: "XL",
    regular: { size: 54, selected: false, avalaible: true },
    long: { size: 106, selected: false, avalaible: true },
    short: { size: 27, selected: false, avalaible: true },
  },
  {
    title: "XXL",
    regular: { size: 56, selected: false, avalaible: true },
    long: { size: 110, selected: false, avalaible: true },
    short: { size: 28, selected: false, avalaible: true },
  },
  {
    title: "3XL",
    regular: { size: 58, selected: false, avalaible: true },
    long: { size: 114, selected: false, avalaible: true },
    short: { size: 29, selected: false, avalaible: true },
  },
  {
    title: "4XL",
    regular: { size: 60, selected: false, avalaible: true },
    long: { size: 118, selected: false, avalaible: true },
    short: { size: 30, selected: false, avalaible: true },
  },
];
export const sizeConversions = [
  {
    title: "90",
    subTittle: "S",
    fields: [
      { key: "International", value: "S" },
      { key: "EU standards", value: "90" },
      { key: "UK/US jacket", value: "36L" },
      { key: "UK/US trouser", value: "30L" },
      { key: "China Jacket", value: "175/92" },
      { key: "China trouser", value: "78L" },
    ],
  },
  {
    title: "94",
    subTittle: "M",
    fields: [
      { key: "International", value: "M" },
      { key: "EU standards", value: "94" },
      { key: "UK/US jacket", value: "38L" },
      { key: "UK/US trouser", value: "32L" },
      { key: "China Jacket", value: "180/96" },
      { key: "China trouser", value: "82L" },
    ],
  },
  {
    title: "98",
    subTittle: "M/L",
    fields: [
      { key: "International", value: "M-L" },
      { key: "EU standards", value: "98" },
      { key: "UK/US jacket", value: "40L" },
      { key: "UK/US trouser", value: "34L" },
      { key: "China Jacket", value: "185/100" },
      { key: "China trouser", value: "88L" },
    ],
  },
  {
    title: "102",
    subTittle: "L",
    fields: [
      { key: "International", value: "L" },
      { key: "EU standards", value: "102" },
      { key: "UK/US jacket", value: "42L" },
      { key: "UK/US trouser", value: "36L" },
      { key: "China Jacket", value: "190/104" },
      { key: "China trouser", value: "92L" },
    ],
  },
  {
    title: "106",
    subTittle: "XL",
    fields: [
      { key: "International", value: "XL" },
      { key: "EU standards", value: "106" },
      { key: "UK/US jacket", value: "44L" },
      { key: "UK/US trouser", value: "38L" },
      { key: "China Jacket", value: "195/108" },
      { key: "China trouser", value: "96L" },
    ],
  },
  {
    title: "110",
    subTittle: "XXL",
    fields: [
      { key: "International", value: "XXL" },
      { key: "EU standards", value: "110" },
      { key: "UK/US jacket", value: "46L" },
      { key: "UK/US trouser", value: "40L" },
      { key: "China Jacket", value: "200/112" },
      { key: "China trouser", value: "100L" },
    ],
  },
  {
    title: "114",
    subTittle: "3XL",
    fields: [
      { key: "International", value: "3XL" },
      { key: "EU standards", value: "114" },
      { key: "UK/US jacket", value: "48L" },
      { key: "UK/US trouser", value: "42L" },
      { key: "China Jacket", value: "205/116" },
      { key: "China trouser", value: "104L" },
    ],
  },
  {
    title: "118",
    subTittle: "4XL",
    fields: [
      { key: "International", value: "4XL" },
      { key: "EU standards", value: "118" },
      { key: "UK/US jacket", value: "50L" },
      { key: "UK/US trouser", value: "44L" },
      { key: "China Jacket", value: "210/120" },
      { key: "China trouser", value: "108L" },
    ],
  },
];
export const services = [
  // {
  //   name: "Chat",
  //   icon: <WechatOutlined style={{ color: "#00000099", fontSize: 30 }} />,
  //   description: "Available now",
  //   link: "#",
  // },
  {
    name: "WhatsApp",
    icon: <WhatsAppOutlined style={{ color: "#00000099", fontSize: 30 }} />,
    description: "437-431-0423",
    link: "#",
    onclick: () => openWhatsApp("+14374310423"),
  },
  {
    name: "Call",
    icon: <PhoneOutlined style={{ color: "#00000099", fontSize: 30 }} />,
    description: "437-431-0423",
    link: "#",
    onclick: () => openDialer("+14374310423"),
  },
];
