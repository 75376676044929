import React, { useEffect, useState } from "react";
import {
  Carousel,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Select,
  message,
} from "antd";
import moment from "moment";
import closeIcon from "../assets/icons/close.png";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useDispatch, useSelector } from "react-redux";
import axiosApi from "../api";
import { removeItemFromBag } from "../store/bagItemReducer/actions";
import { useNavigate } from "react-router-dom";
const currentDate = moment();

function Checkout() {
  const [aciveStep, setAciveStep] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const { user } = useSelector((state) => state);
  const [bagItems, setBagItems] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [itemDetail, setItemDetail] = useState({ name: "", images: [] });
  const navigate = useNavigate();
  const [orderID, setOrderID] = useState(false);
  const state = useSelector((state) => state);
  const [info, setInfo] = useState({
    saved: false,
    edit: true,
    fullName: "",
    phoneNo: user?.phoneNo || "",
    email: user?.email || "",
    address: {
      addressLine1: "",
      additional: "",
      postcode: "",
      city: "",
      company: "",
      country: "",
    },
  });
  const [delivery, setDelivery] = useState({
    edit: false,
    saved: false,
    avaliableOptions: [
      {
        date: currentDate.format("ddd, MMM DD"),
        time: "11:30 AM",
        price: "15:00",
        selected: false,
      },
      {
        date: "SAT, Dec 26",
        time: "15:30 AM",
        price: "15:00",
        selected: false,
      },
    ],
  });
  const showModal = (item) => {
    setVisible(true);
    setItemDetail({
      name: item?.name,
      images: item?.thumbnails,
    });
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  useEffect(() => {
    fetchBagItems();
  }, [state?.bag]);
  const fetchBagItems = () => {
    const temp = state?.bag?.map((item) => {
      return {
        thumbnails: item?.thumbnails,
        qty: 1,
        size: item?.size,
        id: item?.id,
        price: item?.price,
        name: item?.name,
        delivery: "1-2 weeks",
      };
    });
    setBagItems(temp);
  };

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, [aciveStep]);
  const handleInfoChange = (event, name, type = "text", option = "") => {
    const value = type === "dropdown" ? event : event?.target.value;
    if (option === "address") {
      info.address[name] = value;
    } else if (option === "billingAddress") {
      info.billingAddress[name] = value;
    } else {
      info[name] = value;
    }
  };
  const onFinish = (values) => {};
  const { Option } = Select;
  const removeItem = (productId, selectedSize) => {
    dispatch(removeItemFromBag({ productId, selectedSize }));
    fetchBagItems();
  };
  const getTotal = () => {
    let totalPrice = 0;
    for (const item of state?.bag) {
      totalPrice += item.price;
    }
    return totalPrice;
  };
  const countries = [
    // "United States",
    // "United Kingdom",
    // "Australia",
    "Canada",
  ];

  const createOrder = (data, actions) => {
    try {
      const purchaseUnits = state?.bag?.map((item, index) => {
        return {
          reference_id: `unit_${index + 1}`,
          description: item?.description,
          quantity: item.quantity || 1,
          amount: {
            currency_code: "CAD",
            value: item?.price,
          },
        };
      });
      return actions.order
        .create({
          purchase_units: purchaseUnits,
        })
        .then((orderID) => {
          setOrderID(orderID);
          return orderID;
        });
    } catch (error) {
      messageApi.error(
        error?.response?.data?.message || "Something went wrong"
      );
    }
  };

  // check Approval
  const onApprove = async (data, actions) => {
    const payload = {
      userId: user?._id,
      products: state?.bag?.map((item) => {
        return { id: item?._id, name: item?.name, size: item?.size };
      }),
      orderId: data?.orderID,
      userInfo: info,
    };
    try {
      // Capture the order
      const captureDetails = await actions.order.capture();
      const { payer } = captureDetails;
      payload.payer = payer;

      // Create the order on the server
      const response = await axiosApi.post(`/orders/create`, payload);

      if (response.status === 201) {
        bagItems?.map((item) => {
          removeItem(item?.id, item?.size);
        });
        messageApi.open({
          type: "success",
          content: response.data.message,
        });

        navigate("/profile");
      } else {
        messageApi.open({
          type: "error",
          content: "Something went wrong!",
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong!",
      });
    }
  };
  return (
    <div className="h-full min-h-screen w-full flex flex-col gap-10 items-center pb-10 bg-[#fafafa]">
      {contextHolder}
      <h2 className={`text-black placeholder mt-10  font-semibold text-2xl`}>
        Checkout
      </h2>
      <div className="flex xsm:flex-col-reverse md:w-[800px] lg:w-[1000px] xsm:w-full sm:w-full xl:w-[1000px] sm:flex-col-reverse md:flex-row gap-10 h-full ">
        <Form
          name="contact-form"
          onFinish={onFinish}
          className=" flex-col flex gap-3 overflow-auto h-full items-center w-full max-w-[700px] "
        >
          <div
            className={`   overflow-hidden flex rounded border-[0.5px] flex-col  h-full items-center w-full`}
          >
            <div
              className={` ${
                aciveStep === 1
                  ? "bg-black text-white"
                  : "bg-white text-black  "
              } flex  py-4 px-6 justify-between    w-full  h-full items-center `}
            >
              <div className={`  text-sm `}>1. Your information</div>
              {!info.edit && (
                <div
                  onClick={() => {
                    setAciveStep(1);
                    setInfo({ ...info, edit: true, saved: false });
                  }}
                  className={`text-sm underline cursor-pointer `}
                >
                  Edit
                </div>
              )}
            </div>
            {aciveStep === 1 && (
              <>
                {info.edit && (
                  <div
                    className={`w-full py-4 px-6 flex flex-col bg-white items-center justify-center`}
                  >
                    <Form form={form} className="w-full my-5">
                      <div className={`w-full text-sm mb-3`}>Contact</div>
                      <Form.Item
                        name="fullName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your full name!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) =>
                            handleInfoChange(event, "fullName")
                          }
                          placeholder="Full Name"
                          value={info?.fullName}
                          size="large"
                        />
                      </Form.Item>
                      {/* <Form.Item
                        name="phoneNo"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Phone number!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) =>
                            handleInfoChange(event, "phoneNo")
                          }
                          value={info?.phoneNo}
                          placeholder="Phone number"
                          size="large"
                        />
                      </Form.Item> */}
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Email!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) => handleInfoChange(event, "email")}
                          value={info?.email}
                          placeholder="Email"
                          size="large"
                        />
                      </Form.Item>
                      <div className={`w-full text-sm mb-3`}>Address</div>
                      <Form.Item
                        name="addressLine1"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Address line 1!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) =>
                            handleInfoChange(
                              event,
                              "addressLine1",
                              "text",
                              "address"
                            )
                          }
                          value={info?.address?.addressLine1}
                          placeholder="Address line 1"
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item name="additional">
                        <Input
                          onChange={(event) =>
                            handleInfoChange(
                              event,
                              "additional",
                              "text",
                              "address"
                            )
                          }
                          value={info?.address?.additional}
                          placeholder="Adtional (Optional)"
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item
                        name="postcode"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Postode!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) =>
                            handleInfoChange(
                              event,
                              "postcode",
                              "text",
                              "address"
                            )
                          }
                          value={info?.address?.postcode}
                          placeholder="postcode"
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your City!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) =>
                            handleInfoChange(event, "city", "text", "address")
                          }
                          value={info?.address?.city}
                          placeholder="City"
                          size="large"
                        />
                      </Form.Item>

                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please select your country!",
                          },
                        ]}
                      >
                        <Select
                          onChange={(event) =>
                            handleInfoChange(
                              event,
                              "country",
                              "dropdown",
                              "address"
                            )
                          }
                          value={info?.address?.country}
                          placeholder="Select Country"
                          size="large"
                        >
                          {countries.map((country) => (
                            <Option key={country} value={country}>
                              {country}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          form
                            .validateFields()
                            .then(() => {
                              setAciveStep(2);
                              setInfo({
                                ...info,
                                edit: false,
                                saved: true,
                              });
                            })
                            .catch((errorInfo) => {
                              console.error("Validation failed:", errorInfo);
                            });
                        }}
                        className="text-center border-[1px] mt-10 w-full rounded  bg-black text-white p-2 cursor-pointer "
                      >
                        Save & Continue
                      </button>
                    </Form>
                  </div>
                )}
              </>
            )}
            {info?.saved && (
              <div
                className={`w-full pb-4 px-6 grid gap-4  bg-white  grid-cols-2	`}
              >
                <div className="flex flex-col ">
                  <p className="text-black   ">Address</p>

                  <p className="text-stone-400">{info?.fullName + " "}</p>
                  <p className="text-stone-400">{info?.phoneNo}</p>
                  <p className="text-stone-400">
                    {info?.address?.addressLine1}
                  </p>
                  <p className="text-stone-400">{info?.address?.city}</p>
                  <p className="text-stone-400">{info?.address?.postcode}</p>
                  <p className="text-stone-400">{info?.address?.country}</p>
                </div>
              </div>
            )}
          </div>
          <div
            className={`   overflow-hidden flex rounded border-[0.5px] flex-col  h-full items-center w-full`}
          >
            <div
              className={` ${
                aciveStep === 2 ? "bg-black text-white" : "bg-white text-black"
              } flex  py-4 px-6  h-full items-center w-full`}
            >
              <div className={`w-full text-sm `}>2. Delivery</div>
              {!delivery.edit && delivery.saved && (
                <div
                  onClick={() => {
                    setAciveStep(2);
                    setDelivery({ ...delivery, edit: true, saved: false });
                  }}
                  className={`text-sm underline cursor-pointer `}
                >
                  View
                </div>
              )}
            </div>
            {aciveStep === 2 && (
              <div
                className={`w-full py-4 px-6 flex flex-col bg-white items-center justify-center`}
              >
                <div className="w-[300px] my-5">
                  <p className={`w-full text-sm mb-3`}>
                    Your product will be delivers on.
                  </p>
                  <div
                    className={`w-full border-[1px] mb-3 border-blackhover:border-black flex items-center justify-between p-3 rounded`}
                  >
                    <div className="flex flex-col">
                      <p className="text-sm font-semibold ">
                        {currentDate.add(14, "days").format("ddd, MMM DD")}
                      </p>
                      <p className="  text-sm font-normal ">Before 9:00 PM</p>
                    </div>
                    <p className="  text-sm font-normal text-stone-700">FREE</p>
                  </div>

                  <button
                    onClick={() => {
                      setAciveStep(3);
                      setDelivery({
                        ...delivery,
                        edit: false,
                        saved: true,
                      });
                    }}
                    className="text-center border-[1px] mt-5 w-full rounded  bg-black text-white p-2 cursor-pointer "
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
            {delivery?.saved && (
              <div
                className={`w-full pb-4 px-6 grid gap-4  bg-white  grid-cols-2	`}
              >
                <div className="flex flex-col ">
                  <p className="text-stone-400">
                    {currentDate.add(14, "days").format("ddd, MMM DD")}
                  </p>
                  <p className="text-stone-400">Before 9:00 PM</p>
                  <p className="text-stone-400">FREE</p>
                </div>
              </div>
            )}
          </div>
          <div
            className={`   overflow-hidden flex rounded border-[0.5px] flex-col  h-full items-center w-full`}
          >
            <div
              className={` ${
                aciveStep === 3
                  ? "bg-black text-white"
                  : "bg-white text-stone-400  "
              } flex  py-4 px-6    flex-col  h-full items-center w-full`}
            >
              <div className={`w-full text-sm `}>3. Payment</div>
            </div>
            {aciveStep === 3 && (
              <div
                className={`w-full py-4 px-6 flex flex-col bg-white items-center justify-center`}
              >
                <div className="w-[300px] my-5 ">
                  <PayPalButtons
                    style={{ layout: "vertical" }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex rounded py-4 px-6 bg-white border-[0.5px] flex-col  h-full items-center w-full">
            <div className="w-full  flex justify-between ">
              <div className="flex items-end">
                <p className="text-xl font-semibold ">Total</p>
                <span className="text-stone-400 ml-1 text-base font-normal ">
                  Incl. vat
                </span>
              </div>
              <p className="text-lg font-semibold ">${getTotal()}</p>
            </div>
          </div>
        </Form>
        <div className="flex flex-col   xsm:w-full md:max-w-[500px]  p-5 gap-2 rounded bg-white border-[0.5px] ">
          {bagItems?.length > 0 ? (
            <>
              <ul className="text-[12px] mb-5  w-full  ">
                <li className="w-full text-stone-600  mb-1 flex justify-between text-[12px] ">
                  <p>Delivery costs</p>
                  <p>FREE</p>
                </li>
                <li className="w-full  flex justify-between ">
                  <div className="flex items-end">
                    <p className="text-xl font-semibold ">Total</p>
                    <span className="text-stone-400 ml-1 text-base font-normal ">
                      Incl. vat
                    </span>
                  </div>
                  <p className="text-lg font-semibold ">${getTotal()}</p>
                </li>
              </ul>
              {bagItems?.map((item) => (
                <div className="flex sm:mx-1 xsm:mx-1 mb-5 xsm:flex-col sm:flex-col md:flex-row sm:max-h-auto  xsm:max-h-auto items-center md:max-h-[200px] rounded bg-white shadow-md overflow-hidden">
                  <Image
                    preview={false}
                    onClick={() => showModal(item)}
                    src={item?.thumbnails[0]}
                    alt="article image"
                    className="md:w-[120px] cursor-pointer h-full sm:w-full xsm:w-full sm:overflow-hidden xsm:overflow-hidden "
                  />
                  <div className="flex justify-between  xsm:w-full sm:w-full flex-col px-5 gap-5 h-full py-3 w-auto">
                    <div className="flex justify-between w-full">
                      <div className="flex flex-col gap-1">
                        <div className=" text-sm">{item?.name}</div>
                        <p className="text-stone-400 text-[10px]">
                          Delivery: free
                        </p>
                      </div>
                      <Image
                        preview={false}
                        src={closeIcon}
                        alt="closeIcon"
                        onClick={() => {
                          removeItem(item?.id, item?.size);
                        }}
                        className=" cursor-pointer h-4 "
                      />
                    </div>
                    <div className="flex justify-between w-full">
                      <div className="flex justify-between gap-10">
                        <div className="flex gap-1">
                          <div className=" text-sm text-stone-500 ">Qty. 1</div>
                        </div>
                        <div className=" text-sm text-stone-500 ">
                          Size {item?.size}
                        </div>
                      </div>
                      <div className=" text-lg">${item?.price}</div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="flex flex-col gap-5 mt-10  ">
              <h4 className="text-black text-center text-xl ">
                Your bag is still empty
              </h4>
              <h3
                className="underline cursor-pointer text-black text-center text-2xl "
                onClick={() => navigate("/explore/Suits")}
              >
                Start shopping
              </h3>
            </div>
          )}
        </div>
      </div>
      <Modal
        title={itemDetail?.name}
        visible={visible}
        onOk={handleOk}
        footer={[]}
        onCancel={handleCancel}
      >
        <Carousel autoplaySpeed={1200} autoplay>
          {itemDetail?.images?.map((image) => (
            <div>
              <Image
                preview={false}
                src={image}
                alt="article image"
                className=" h-auto w-auto"
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    </div>
  );
}

export default Checkout;
