import { FileImageOutlined } from "@ant-design/icons";
import React from "react";

const NotFound = () => {
  return (
    <div>
      <div className=" h-screen w-full grid place-items-center ">
        <p className="text-4xl text-stone-200">
          <FileImageOutlined /> 404 - Page Not Found
        </p>
      </div>
    </div>
  );
};

export default NotFound;
