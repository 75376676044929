import React from "react";
import { Link, useParams } from "react-router-dom";

const Tabs = () => {
  const { item } = useParams();

  const tabs = [
    "Suits",
    "Shirts",
    "Trousers",
    "Coats & Vests",
    "Jackets & Blazers",
  ];

  return (
    <div className="w-full flex items-center gap-1 ">
      <div className="flex gap-2 items-center flex-wrap">
        {tabs?.map((tab) => (
          <Link
            to={`/explore/${tab}`}
            className={` ${
              item === tab ? "border-stone-500" : "border-stone-200"
            } text-base cursor-pointer hover:border-stone-500  border-[1px] px-2 py-1 rounded`}
            key={tab}
          >
            {tab}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
