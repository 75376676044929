import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      className="flex items-center justify-center bg-transparent text-black hover:text-gray-800 focus:outline-none"
    >
      <ArrowLeftOutlined style={{ fontSize: "18px" }} />
    </button>
  );
};

export default BackButton;
