import React, { useState, useCallback, useRef, useEffect } from "react";
import { useTransition, animated } from "@react-spring/web";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { Image, message } from "antd";
import logo from "../assets/logo.png";
import Loader from "../component/shared/Loader";
function Dashboard() {
  const [dashboard, setDashboard] = useState([]);
  const ref = useRef([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [items, set] = useState([]);
  const navigate = useNavigate();
  const transitions = useTransition(items, {
    from: {
      opacity: 0,
      height: 0,
      innerHeight: 0,
      transform: "perspective(600px) rotateX(0deg)",
      color: "#e6e6e6",
    },
    enter: [
      { opacity: 1, height: 40, innerHeight: 20 },
      { transform: "perspective(600px) rotateX(180deg)", color: "#fff" },
      { transform: "perspective(600px) rotateX(0deg)" },
    ],
    leave: [
      { color: "#e6e6e6" },
      { innerHeight: 0 },
      { opacity: 0, height: 0 },
    ],
    update: { color: "#fff" },
  });
  const listItems = [
    "Suits",
    "Jackets & Blazers",
    "Shirts",
    "Trousers",
    "Coats & Vests",
  ];
  const listItems2 = ["Jackets & Blazers", "Shirts", "Coats & Vests"];
  const reset = useCallback(() => {
    ref.current.forEach(clearTimeout);
    ref.current = [];
    set([]);
    ref.current.push(setTimeout(() => set(listItems2), 2000));
    ref.current.push(setTimeout(() => set(listItems), 5000));
  }, []);

  useEffect(() => {
    getDashboard();
    reset();
    return () => ref.current.forEach(clearTimeout);
  }, []);

  const getDashboard = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/dashboard/getDefault`);

      if (response.status === 200) {
        const temp = response?.data?.images;
        setDashboard(temp);
      }
    } catch (error) {
      // messageApi.error(
      //   error?.response?.data?.message || "Something went wrong"
      // );
    }
    setLoading(false);
  };

  return (
    <div className="w-full h-full ">
      <Loader loading={loading} />
      {contextHolder}
      <div className="">
        <div className="w-full  h-full">
          {dashboard?.length > 0 ? (
            dashboard?.map((image, index) => (
              <div className="w-full" key={index}>
                <Image
                  preview={false}
                  src={image}
                  alt="image"
                  className="w-full h-full"
                />
              </div>
            ))
          ) : (
            <div className="w-full h-4/5 flex flex-col gap-3 min-h-screen items-center justify-center">
              <h1 className="text-4xl w-full text-center ">Welcome to</h1>
              <img preview={false} src={logo} alt="sarts-logo" />
              <h6 className="max-w-[250px] text-center text-[12px]">
                <span
                  onClick={() => navigate("explore/Suits")}
                  className="cursor-pointer  hover:underline text-blue-800"
                >
                  Click here
                </span>{" "}
                to start shopping
              </h6>
            </div>
          )}
        </div>
        {/* <div className="h-full flex justify-end relative flex-col w-full ">
          {transitions(({ innerHeight, ...rest }, item) => (
            <animated.div
              className="overflow-hidden absolute w-full left-0 h-full bottom-0 top-0 flex items-center justify-start md:text-4xl sm:text-3xl xsm:text-2xl text-6xl font-extrabold cursor-pointer"
              style={rest}
              onClick={() => navigate(`explore/${item}`)}
            >
              <animated.div>{item}</animated.div>
            </animated.div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
