import React, { useState, useEffect } from "react";
import { Image as AntdImage, Skeleton } from "antd";

function ArticleCard({ onClick = () => {}, article, size = null }) {
  const { thumbnail, price, description, title } = article;
  const [loading, setLoading] = useState(true);

  const truncateStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  useEffect(() => {
    const img = new Image();
    img.src = thumbnail;
    img.onload = () => setLoading(false);
  }, [thumbnail]);

  return (
    <div
      onClick={onClick}
      className="article-card w-full h-full rounded cursor-pointer overflow-hidden shadow-md"
    >
      {loading ? (
        <Skeleton
          active
          className="w-full h-full m-3"
          avatar
          paragraph={{ rows: 3 }}
        />
      ) : (
        <>
          <AntdImage
            preview={false}
            src={thumbnail}
            alt="Card Image"
            className="article-card-image"
          />

          <div className="px-6 py-4 w-full flex items-center border-t-2 justify-between">
            <div className="flex flex-col gap-[2px]">
              <div className="font-bold text-xl cursor-pointer">{title}</div>
              <div className="App" style={{ width: "300px" }}>
                <p className="text-gray-400 text-[12px]" style={truncateStyle}>
                  {description}
                </p>
              </div>

              {size ? (
                <p className="text-gray-700 text-[14px]">size: {size}</p>
              ) : (
                <p className="text-gray-700 text-[14px]">${price}</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ArticleCard;
