import {
  ArrowLeftOutlined,
  EditOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Modal, Carousel, message, Timeline, Image } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import Loader from "../component/shared/Loader";

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [itemDetail, setItemDetail] = useState({
    name: "",
    images: [],
    description: "",
  });
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!user?.token) {
      navigate("/login");
    }
  }, [user]);
  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    setLoading(true);
    try {
      const userId = user._id;
      const response = await api.get(`/orders/user/${userId}`);
      setOrders(response?.data || []);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error?.response?.data?.message || "Fail to fetch orders!",
      });
    }
    setLoading(false);
  };
  const showModal = async (item) => {
    setLoading(true);

    const id = item[0].id;
    try {
      const response = await api.get(`/items/${id}`);
      if (response?.status === 200 && response?.data) {
        const temp = response?.data;
        setItemDetail({
          name: temp?.name,
          images: temp?.thumbnails,
          price: temp?.price,
          description: temp?.description,
        });
        setVisible(true);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error?.response?.data?.message || "Fail to fetch orders!",
      });
    }
    setLoading(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const logout = () => {
    dispatch({ type: "SET_USER", payload: { token: null } });
  };
  return (
    <div className="h-full overflow-auto min px-8 sm:min-w-[600px] xsm:min-w-full md:min-w-[800px] lg:min-w-[1000px] xl:min-w-[1200px] 2xl:min-w-[1500px] flex flex-col  py-10">
      {contextHolder}
      <Loader loading={loading} />
      <Button
        shape="circle"
        onClick={() => navigate("/")}
        icon={<ArrowLeftOutlined />}
        className="border-none my-[50px]"
      />
      <h2 className="text-black mb-2 font-semibold text-2xl">
        Welcome {user?.fullName}
      </h2>

      <div className="flex flex-col xsm:w-full w-full my-10 p-5 gap-2 rounded bg-white border-[0.5px] ">
        {orders?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10">
            {orders?.map((item) => (
              <div
                key={item.id}
                className="shadow-md p-5 h-auto flex md:flex-row flex-col "
              >
                <div className="w-full h-full ">
                  <Timeline
                    items={[
                      {
                        color: item?.status === "Pending" ? "red" : "green",
                        children: "Pending",
                      },
                      {
                        color:
                          item?.status === "Dispatched" ||
                          item?.status === "Shipped" ||
                          item?.status === "Delivered"
                            ? "green"
                            : "gray",
                        children: "Dispatched",
                      },
                      {
                        color:
                          item?.status === "Shipped" ||
                          item?.status === "Delivered"
                            ? "green"
                            : "gray",
                        children: "Shipped",
                      },
                      {
                        color: item?.status === "Delivered" ? "green" : "gray",
                        children: "Delivered",
                      },
                    ]}
                  />
                </div>
                <div className="flex gap-3 w-full h-full flex-col">
                  {item?.products?.map((product, index) => (
                    <div
                      key={index}
                      className="flex justify-between cursor-pointer border-[1px] h-24 rounded p-3 w-full"
                    >
                      <div className="flex flex-col gap-1">
                        <div className="text-sm">
                          #{index + 1} {product[0]?.name || "-"}
                        </div>
                        <div className="text-sm text-stone-500">
                          Size: {product[0]?.size || "-"}
                        </div>
                        <div
                          onClick={() => showModal(product)}
                          className="text-sm text-blue-500 underline cursor-pointer"
                        >
                          check details
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">
            <h4 className="text-black text-base ">
              Your orders will display here!{" "}
              <span
                className="underline cursor-pointer text-blue-500"
                onClick={() => navigate("/explore/Suits")}
              >
                Start shopping
              </span>
            </h4>
          </div>
        )}
      </div>

      {user?.isAdmin && (
        <Button
          className="text-[14px] self-start my-3   "
          icon={<UserOutlined />}
          size="small"
          onClick={() => navigate("admin")}
        >
          Admin Settings
        </Button>
      )}

      <p className="text-lg text-stone font-semibold ">My information </p>
      <div className="w-full border-b-[1px] my-3 "></div>
      <div className=" gap-10 mt-5 flex flex-col lg:flex-row lg:justify-between">
        <div className="">
          <p className="text-[14px] text-stone-700 mb-3  ">Profile </p>
          <p className="text-[14px] text-stone-700   ">{user?.fullName}</p>
          <p className="text-[14px] text-stone-700   ">{user?.email}</p>
        </div>
        {/* <div className="">
          <p className="text-[14px] text-stone-700 ">Address book </p>
          <Button
            className="text-[14px] text-stone-400 mb-3   "
            type="text"
            icon={<PlusOutlined />}
            size="small"
          >
            Add address
          </Button>
        </div> */}
      </div>

      <p className="text-lg text-stone font-semibold">Tips</p>
      <div className="w-full border-b-[1px] my-3 "></div>
      <Card
        style={{ borderRadius: 5 }}
        className="mt-5 mb-10 w-[440px] xsm:w-[300px] "
        cover={
          <Image
            preview={false}
            alt="example"
            src="	https://cdn.suitsupply.com/image/upload/suitsupply/assets/ss21/my-account/my-account-2021.jpg"
          />
        }
        // actions={[
        //   <Button
        //     className="text-[14px] text-stone-400 mb-3   "
        //     type="link"
        //     size="small"
        //   >
        //     Read more
        //   </Button>,
        // ]}
      >
        <Meta
          title="Returns"
          description="If your Sart purchase does not meet your expectations or if you change your mind."
        />
      </Card>
      <Button
        className="text-[14px] text-stone-600 my-3 self-center "
        size="large"
        onClick={logout}
      >
        Log out
      </Button>
      <Modal
        title={itemDetail?.name}
        visible={visible}
        onOk={handleOk}
        footer={[]}
        onCancel={handleCancel}
      >
        <div>
          <Carousel autoplaySpeed={1200} autoplay>
            {itemDetail?.images?.map((image) => (
              <div>
                <Image
                  preview={false}
                  src={image}
                  alt="article image"
                  className=" h-auto w-auto"
                />
              </div>
            ))}
          </Carousel>
          <p className="mt-2">Description: {itemDetail?.description}</p>
        </div>
      </Modal>
    </div>
  );
}

export default Profile;
