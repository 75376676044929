import { applyMiddleware, combineReducers, createStore } from "redux";
import userReducer from "./userReducer";
import thunk from "redux-thunk";
import bagItemsReducer from "./bagItemReducer";

// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

// Save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (error) {
    console.error("Error saving state to localStorage:", error);
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  bag: bagItemsReducer,
});

// Create Redux store with persisted state
const persistedState = loadState();
const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

// Subscribe to store changes and save state to localStorage
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
