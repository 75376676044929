import {
  DropboxOutlined,
  EnvironmentOutlined,
  InstagramFilled,
  UserSwitchOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StyleExpert from "./shared/StyleExpert";
import { copyToClipboard, openDialer, openWhatsApp } from "./shared/functions";

const Footer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <footer className="bg-gray-950 text-white w-full  ">
      <div className=" mb-5  p-10 flex items-center  gap-10 ">
        <ul className="flex flex-col gap-1">
          <li className="text-3xl mb-2">
            <UserSwitchOutlined />
          </li>
          <li className="text-xl font-semibold ">Get Personal style advice</li>
          <li className="text-sm">
            have a style or fir question? Chat online.
          </li>
          <li
            className="text-sm underline cursor-pointer "
            onClick={() => setOpen(true)}
          >
            Ask a Style expert
          </li>
        </ul>
        <ul className="flex flex-col gap-1">
          <li className="text-3xl mb-2">
            <DropboxOutlined />
          </li>
          <li className="text-xl font-semibold ">Free shipping & Returns</li>
          <li className="text-sm">Enjoy free shipping on every order.</li>
          <li
            className="text-sm underline cursor-pointer"
            onClick={() => navigate("/support")}
          >
            Learn more
          </li>
        </ul>
      </div>
      <div className="  border-t-[0.2px] border-[#6e6a6ac0]  p-10 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xsm:grid-cols-2 gap-10 ">
        <ul className="flex flex-col gap-4 col-span-2 ">
          <li className="text-sm font-bold ">Contact</li>
          <li className="text-sm flex items-center">
            <p className="min-w-[100px]">WhatsApp:</p>
            <p
              className="min-w-[100px]  cursor-pointer hover:underline"
              onClick={() => openWhatsApp("+14374310423")}
            >
              437-431-0423
            </p>
          </li>
          <li className="text-sm flex  items-center">
            <p className="min-w-[100px]">Call:</p>
            <p
              className="min-w-[100px]  cursor-pointer hover:underline"
              onClick={() => openDialer("+14374310423")}
            >
              437-431-0423
            </p>
          </li>
          <li className="text-sm flex items-center">
            <p className="min-w-[100px]">Email:</p>
            <p
              className="min-w-[100px]  cursor-pointer hover:underline"
              onClick={() => copyToClipboard("infosarts@gmail.com")}
            >
              infosarts@gmail.com
            </p>
          </li>
        </ul>
        <ul className="grid grid-cols-2 gap-4">
          <li className="text-sm font-bold ">Shop by</li>
          <li></li>
          {["Suits", "Jackets & Blazers", "Trousers", "shirts", "Coats"]?.map(
            (item) => (
              <li
                onClick={() => navigate(`explore/${item}`)}
                className="hover:underline cursor-pointer text-sm"
              >
                {item}
              </li>
            )
          )}
        </ul>
        <ul className="flex flex-col gap-4">
          <li className="text-sm font-bold ">Support</li>
          <li className="text-sm">Shoping & Delivery </li>
          <li className="text-sm">Returns & Exchanges</li>
          <li className="text-sm">Perfect Fit Guides</li>
        </ul>
        {/* <ul className="flex flex-col gap-4">
          <li className="text-sm font-bold ">About</li>
          <li
            onClick={() => navigate("/about")}
            className="text-sm cursor-pointer hover:underline "
          >
            Our Story
          </li>
        </ul> */}
      </div>
      <div className="w-full px-5">
        <div className="w-full gap-5 text-sm md:flex-row flex sm:flex-col xsm:flex-col items-center justify-between mt-5 bg-gray-950 text-white px-10 py-5 border-t-[0.2px] border-[#6e6a6ac0]  ">
          <p>
            <EnvironmentOutlined /> Canada | English
          </p>
          <div className="flex items-center gap-5"></div>
          <a
            className="cursor-pointer"
            href="https://www.instagram.com/sarts_suiting?igsh=Mmt6Zjk1bXNiZTJw&utm_source=qr"
            target="_blank"
          >
            <InstagramFilled className="mr-2" />
            <span className="hover:underline">Follow us on Instagram</span>
          </a>
        </div>
      </div>
      <StyleExpert open={open} close={() => setOpen(false)} />
    </footer>
  );
};

export default Footer;
